import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Data } from '../data';
import '../styles/VocabularyPage.css';
import WordDefinitionPopup from './WordDefinitionPopup';

function VocabularyPage({ userStats, setUserStats, customWords: initialCustomWords, excludedWords: initialExcludedWords }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWords, setFilteredWords] = useState([]);
  const [totalWords, setTotalWords] = useState(0);
  const [selectedLetters, setSelectedLetters] = useState(['all']);
  const [filterOptions, setFilterOptions] = useState({
    mastery: 'all', // all, mastered, in-progress, not-started
    sortBy: 'mastery', // mastery, alphabetical, recent
    difficulty: 'all', // all, easy, medium, hard
  });
  const [showLetterFilter, setShowLetterFilter] = useState(false);

  // Add new state for media popup
  const [mediaPopup, setMediaPopup] = useState({
    isOpen: false,
    type: null, // 'image' or 'video'
    content: [],
    currentIndex: 0
  });

  const [editMode, setEditMode] = useState(false);
  const [markedWords, setMarkedWords] = useState(initialExcludedWords || {});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const alphabetCategories = ['all', ...Array.from('abcdefghijklmnopqrstuvwxyz')];

  // Add these state variables
  const [playingAudio, setPlayingAudio] = useState(null);

  // Add this near your other state declarations
  const [expandedCards, setExpandedCards] = useState({});

  // Get mastered synonyms from quiz history
  const getMasteredSynonyms = (word) => {
    const masteredSynonyms = new Set();
    
    // Add excluded synonyms to mastered set
    if (markedWords[word]?.fully) {
      // If word is fully excluded, add all its synonyms
      const wordData = Data.find(item => item.word === word) || 
                      initialCustomWords.find(item => item.word === word);
      wordData?.synonyms.forEach(synonym => masteredSynonyms.add(synonym));
    } else if (markedWords[word]?.synonyms) {
      // Add individually excluded synonyms
      markedWords[word].synonyms.forEach(synonym => masteredSynonyms.add(synonym));
    }

    // Add synonyms mastered through quizzes
    userStats.quizHistory.forEach(quiz => {
      const wordResult = quiz.wordResults?.[word];
      if (wordResult?.correct) {
        wordResult.correct.forEach(synonym => masteredSynonyms.add(synonym));
      }
    });

    return masteredSynonyms;
  };

  const getWordMasteryPercentage = (word, masteredSynonyms) => {
    const wordData = Data.find(item => item.word === word) || 
                    initialCustomWords.find(item => item.word === word);
    if (!wordData) return 0;
    
    // If word is fully excluded, return 100%
    if (markedWords[word]?.fully) return 100;
    
    return Math.round((masteredSynonyms.size / wordData.synonyms.length) * 100);
  };

  const getWordDifficulty = (word) => {
    const synonymCount = word.synonyms.length;
    if (synonymCount <= 3) return 'easy';
    if (synonymCount <= 5) return 'medium';
    return 'hard';
  };

  // Add letter selection handler
  const handleLetterSelect = (letter) => {
    let newSelection;
    if (letter === 'all') {
      newSelection = ['all'];
    } else {
      newSelection = selectedLetters.includes('all') 
        ? [letter] 
        : selectedLetters.includes(letter)
          ? selectedLetters.filter(l => l !== letter)
          : [...selectedLetters, letter];
      
      if (newSelection.length === 0) {
        newSelection = ['all'];
      }
    }
    setSelectedLetters(newSelection);
  };

  // Filter and sort words based on search term and filters
  useEffect(() => {
    let filtered = Data.filter(wordData => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = searchTerm === '' || 
        wordData.word.toLowerCase().includes(searchLower) ||
        wordData.definition.toLowerCase().includes(searchLower) ||
        wordData.synonyms.some(syn => syn.toLowerCase().includes(searchLower));

      // Add letter filtering
      const matchesLetter = selectedLetters.includes('all') || 
        selectedLetters.some(letter => 
          wordData.word.toLowerCase().startsWith(letter.toLowerCase())
        );

      const masteredSynonyms = getMasteredSynonyms(wordData.word);
      const masteryPercentage = getWordMasteryPercentage(wordData.word, masteredSynonyms);
      
      // Apply all filters
      const matchesMastery = filterOptions.mastery === 'all' ||
        (filterOptions.mastery === 'mastered' && masteryPercentage === 100) ||
        (filterOptions.mastery === 'in-progress' && masteryPercentage > 0 && masteryPercentage < 100) ||
        (filterOptions.mastery === 'not-started' && masteryPercentage === 0);

      const matchesDifficulty = filterOptions.difficulty === 'all' ||
        getWordDifficulty(wordData) === filterOptions.difficulty;

      return matchesSearch && matchesLetter && matchesMastery && matchesDifficulty;
    });

    // Apply sorting
    filtered = [...filtered].sort((a, b) => {
      if (filterOptions.sortBy === 'alphabetical') {
        return a.word.localeCompare(b.word);
      } else if (filterOptions.sortBy === 'mastery') {
        const aMastered = getMasteredSynonyms(a.word);
        const bMastered = getMasteredSynonyms(b.word);
        return getWordMasteryPercentage(b.word, bMastered) - 
               getWordMasteryPercentage(a.word, aMastered);
      }
      return 0;
    });

    setFilteredWords(filtered);
    setTotalWords(filtered.length);
  }, [searchTerm, filterOptions, userStats, selectedLetters]);

  const handleFilterChange = (filterType, value) => {
    setFilterOptions(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  // Handle media icon clicks
  const handleMediaClick = (type, content, e) => {
    e.stopPropagation();
    if (content && content[0] !== "") {
      setMediaPopup({
        isOpen: true,
        type,
        content,
        currentIndex: 0
      });
    }
  };

  // Handle carousel navigation
  const handleCarouselNav = (direction) => {
    setMediaPopup(prev => ({
      ...prev,
      currentIndex: direction === 'next'
        ? (prev.currentIndex + 1) % prev.content.length
        : (prev.currentIndex - 1 + prev.content.length) % prev.content.length
    }));
  };

  // Handle marking/unmarking words
  const handleMarkWord = (wordData) => {
    const masteredSynonyms = getMasteredSynonyms(wordData.word);
    
    setMarkedWords(prev => {
      const newMarkedWords = { ...prev };
      
      // If word is currently marked/mastered, unmark everything
      if (newMarkedWords[wordData.word]?.fully || 
          wordData.synonyms.every(syn => masteredSynonyms.has(syn))) {
        // Remove all markings for this word
        delete newMarkedWords[wordData.word];
      } else {
        // Mark the word and all its synonyms
        newMarkedWords[wordData.word] = {
          fully: true,
          synonyms: []
        };
      }
      
      return newMarkedWords;
    });
    setHasUnsavedChanges(true);
  };

  // Handle marking/unmarking synonyms
  const handleMarkSynonym = (word, synonym) => {
    const masteredSynonyms = getMasteredSynonyms(word);
    
    setMarkedWords(prev => {
      const wordMarks = prev[word] || { fully: false, synonyms: [] };
      
      // If word is fully marked, convert to individual synonym marks
      if (wordMarks.fully) {
        // Get all synonyms except the one being unmarked
        const wordData = Data.find(item => item.word === word) || 
                        initialCustomWords.find(item => item.word === word);
        const newSynonyms = wordData.synonyms.filter(s => s !== synonym);
        
        return {
          ...prev,
          [word]: {
            fully: false,
            synonyms: newSynonyms
          }
        };
      }
      
      // Normal synonym toggle logic
      const newSynonyms = wordMarks.synonyms?.includes(synonym)
        ? wordMarks.synonyms.filter(s => s !== synonym)
        : [...(wordMarks.synonyms || []), synonym];
      
      const newMarkedWords = {
        ...prev,
        [word]: {
          ...wordMarks,
          synonyms: newSynonyms
        }
      };
      
      // Remove word entry if no synonyms are marked and word isn't fully marked
      if (newSynonyms.length === 0 && !newMarkedWords[word].fully) {
        delete newMarkedWords[word];
      }
      
      return newMarkedWords;
    });
    setHasUnsavedChanges(true);
  };

  // Handle saving changes
  const handleSaveChanges = () => {
    // Save to localStorage
    localStorage.setItem('excludedWords', JSON.stringify(markedWords));
    
    // Update the parent component's state through props
    setUserStats(prev => ({
      ...prev,
      excludedWords: markedWords
    }));
    
    // Exit edit mode
    setEditMode(false);
    
    // Reset unsaved changes flag
    setHasUnsavedChanges(false);
  };

  // Add useEffect to sync with initialExcludedWords
  useEffect(() => {
    setMarkedWords(initialExcludedWords || {});
  }, [initialExcludedWords]);

  // Add this function before the return statement
  const handlePronunciation = async (word, barElement) => {
    try {
      // If already playing this word, stop it
      if (playingAudio === word) {
        window.speechSynthesis.cancel();
        setPlayingAudio(null);
        barElement.classList.remove('playing');
        return;
      }

      // Cancel any ongoing speech
      window.speechSynthesis.cancel();

      // Add playing class to show animation
      barElement.classList.add('playing');
      setPlayingAudio(word);

      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(word);
        utterance.lang = 'en-US';
        utterance.rate = 0.9;

        // Handle completion
        utterance.onend = () => {
          setPlayingAudio(null);
          barElement.classList.remove('playing');
        };

        // Handle errors
        utterance.onerror = () => {
          console.error('Error playing pronunciation for:', word);
          setPlayingAudio(null);
          barElement.classList.remove('playing');
        };

        window.speechSynthesis.speak(utterance);
      } else {
        console.error('Text-to-speech not supported in this browser');
        setPlayingAudio(null);
        barElement.classList.remove('playing');
      }

    } catch (error) {
      console.error('Error with pronunciation:', error);
      setPlayingAudio(null);
      barElement.classList.remove('playing');
    }
  };

  const [selectedText, setSelectedText] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [definitionData, setDefinitionData] = useState(null);
  const [showDefinitionPopup, setShowDefinitionPopup] = useState(false);

  useEffect(() => {
    const handleSelection = async () => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      // Only proceed if there's actually selected text
      if (selectedText.length > 0) {
        // Check if the selected text is a word or synonym in Data.js
        const isInData = Data.some(word => 
          word.word.toLowerCase() === selectedText.toLowerCase() || 
          word.synonyms.some(syn => syn.toLowerCase() === selectedText.toLowerCase())
        );

        if (!isInData) {
          setShowDefinitionPopup(false);
          return;
        }

        // Get the selection range and its bounding rectangle
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        // Calculate position (not needed anymore since popup is fixed)
        setSelectedText(selectedText);

        try {
          const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${selectedText}`);
          if (response.ok) {
            const data = await response.json();
            setDefinitionData(data[0]);
            setShowDefinitionPopup(true);
          } else {
            console.error('Word not found or API error');
            setDefinitionData(null);
            setShowDefinitionPopup(false);
          }
        } catch (error) {
          console.error('Failed to fetch word data:', error);
          setDefinitionData(null);
          setShowDefinitionPopup(false);
        }
      }
    };

    // Add mouseup event listener to handle text selection
    document.addEventListener('mouseup', handleSelection);

    // Add click event listener to close popup when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest('.word-definition-popup')) {
        setShowDefinitionPopup(false);
        setDefinitionData(null);
        setSelectedText('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mouseup', handleSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="vocabulary-page">
      <div className="header-left">
        <Link to="/" className="back-button">
          <span>←</span> Back
        </Link>
        <h1>Vocabulary Progress</h1>
      </div>

      <div className="edit-controls">
        <button 
          className={`edit-mode-button ${editMode ? 'active' : ''}`}
          onClick={() => setEditMode(!editMode)}
        >
          <span className="edit-icon">✎</span>
          {editMode ? 'Exit Edit Mode' : 'Edit Words'}
        </button>
        {hasUnsavedChanges && (
          <button 
            className="save-changes-button"
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
        )}
      </div>

      <div className="search-and-filter-container">
        <div className="search-container">
          <div className="search-input-wrapper">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search words, definitions, or synonyms..."
              className="search-input"
            />
            {searchTerm && (
              <button 
                className="clear-search"
                onClick={() => setSearchTerm('')}
                aria-label="Clear search"
              >
                ×
              </button>
            )}
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-group">
            <label>Letter Filter:</label>
            <div className="dropdown-filter">
              <button 
                className="dropdown-trigger"
                onClick={() => setShowLetterFilter(!showLetterFilter)}
              >
                {selectedLetters.includes('all') 
                  ? 'All Letters' 
                  : selectedLetters.map(l => l.toUpperCase()).join(', ')}
                <span className={`arrow ${showLetterFilter ? 'up' : 'down'}`}>▼</span>
              </button>
              
              {showLetterFilter && (
                <div className="letter-filter-dropdown">
                  <div className="letter-buttons">
                    {alphabetCategories.map(letter => (
                      <button
                        key={letter}
                        onClick={() => handleLetterSelect(letter)}
                        className={`letter-button ${
                          selectedLetters.includes(letter) ? 'selected' : ''
                        }`}
                      >
                        {letter.toUpperCase()}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="filter-group">
            <label>Mastery:</label>
            <select 
              value={filterOptions.mastery}
              onChange={(e) => handleFilterChange('mastery', e.target.value)}
            >
              <option value="all">All</option>
              <option value="mastered">Mastered</option>
              <option value="in-progress">In Progress</option>
              <option value="not-started">Not Started</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Sort by:</label>
            <select 
              value={filterOptions.sortBy}
              onChange={(e) => handleFilterChange('sortBy', e.target.value)}
            >
              <option value="mastery">Mastery</option>
              <option value="alphabetical">Alphabetical</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Difficulty:</label>
            <select 
              value={filterOptions.difficulty}
              onChange={(e) => handleFilterChange('difficulty', e.target.value)}
            >
              <option value="all">All</option>
              <option value="easy">Easy (1-3 synonyms)</option>
              <option value="medium">Medium (4-5 synonyms)</option>
              <option value="hard">Hard (6+ synonyms)</option>
            </select>
          </div>
        </div>

        <div className="search-results-count">
          {searchTerm ? (
            `Found ${totalWords} ${totalWords === 1 ? 'word' : 'words'}`
          ) : (
            `${totalWords} total words`
          )}
        </div>
      </div>

      <div className="vocabulary-list">
        {filteredWords.length > 0 ? (
          filteredWords.map((wordData) => {
            const masteredSynonyms = getMasteredSynonyms(wordData.word);
            const masteryPercentage = getWordMasteryPercentage(
              wordData.word, 
              masteredSynonyms
            );

            return (
              <div key={wordData.word} className="vocabulary-card">
                <div className="word-header" onClick={() => {
                  // Add state for expanded cards
                  const newExpandedCards = {...expandedCards};
                  newExpandedCards[wordData.word] = !expandedCards[wordData.word];
                  setExpandedCards(newExpandedCards);
                }}>
                  <div className="word-title-container">
                    <div className="word-info">
                      <h2>{wordData.word}</h2>
                      {wordData['part-of-speech'] && wordData['part-of-speech'].length > 0 && (
                        <span className="part-of-speech">
                          {wordData['part-of-speech'].join(', ')}
                        </span>
                      )}
                    </div>
                    <p className="primary-definition">
                      {Array.isArray(wordData.definition) 
                        ? wordData.definition[0] 
                        : wordData.definition}
                    </p>
                  </div>
                  <div className="card-actions">
                    <div className="mastery-badge" 
                      style={{
                        backgroundColor: `rgba(${
                          masteryPercentage >= 100 ? '74, 222, 128' : 
                          masteryPercentage >= 50 ? '0, 210, 255' : 
                          '255, 68, 68'
                        }, 0.1)`,
                        color: `${
                          masteryPercentage >= 100 ? 'var(--secondary-color)' : 
                          masteryPercentage >= 50 ? 'var(--primary-color)' : 
                          'var(--error-color)'
                        }`
                      }}
                    >
                      {masteryPercentage}% Mastered
                    </div>
                    <button 
                      className="expand-button"
                      title={expandedCards[wordData.word] ? "Show less" : "Show more"}
                    >
                      <svg 
                        viewBox="0 0 24 24" 
                        width="24" 
                        height="24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                        style={{
                          transform: expandedCards[wordData.word] ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: 'transform 0.3s ease'
                        }}
                      >
                        <path d="M6 9l6 6 6-6"/>
                      </svg>
                    </button>
                  </div>
                </div>

                {/* Always visible synonyms section */}
                <div className="synonyms-container compact">
                  <div className="synonyms-grid">
                    {wordData.synonyms.map((synonym) => (
                      <div key={synonym} className="synonym-container">
                        <span 
                          className={`synonym-tag ${
                            masteredSynonyms.has(synonym) || 
                            markedWords[wordData.word]?.fully || 
                            markedWords[wordData.word]?.synonyms?.includes(synonym) 
                              ? 'mastered' 
                              : ''
                          }`}
                        >
                          {synonym}
                          {editMode && (
                            <button
                              className={`mark-synonym-button ${
                                masteredSynonyms.has(synonym) || 
                                markedWords[wordData.word]?.fully || 
                                markedWords[wordData.word]?.synonyms?.includes(synonym) 
                                  ? 'mastered' 
                                  : ''
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMarkSynonym(wordData.word, synonym);
                              }}
                            >
                              {masteredSynonyms.has(synonym) || 
                               markedWords[wordData.word]?.fully || 
                               markedWords[wordData.word]?.synonyms?.includes(synonym) 
                                 ? '✕' 
                                 : '✓'}
                            </button>
                          )}
                        </span>
                        <button
                          className={`pronunciation-bar ${playingAudio === synonym ? 'playing' : ''}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePronunciation(synonym, e.target);
                          }}
                          title={`Click to hear pronunciation of "${synonym}"`}
                          aria-label={`Pronounce ${synonym}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Expandable content */}
                {expandedCards[wordData.word] && (
                  <div className="expanded-content">
                    {/* Additional definitions */}
                    {Array.isArray(wordData.definition) && wordData.definition.length > 1 && (
                      <div className="definitions-section">
                        <h3>Additional Definitions:</h3>
                        <div className="definitions-list">
                          {wordData.definition.slice(1).map((def, index) => (
                            <div key={index} className="definition-item">
                              <span className="definition-number">{index + 2}.</span>
                              <p>{def}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Example Sentences */}
                    {wordData.sentences && wordData.sentences.length > 0 && wordData.sentences[0] !== "" && (
                      <div className="sentences-section">
                        <h3>Example Sentences:</h3>
                        <div className="sentences-list">
                          {wordData.sentences.map((sentence, index) => (
                            <div key={index} className="sentence-item">
                              <span className="sentence-number">{index + 1}.</span>
                              <p>{sentence}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Media Section */}
                    {((wordData.media && wordData.media.length > 0 && wordData.media[0] !== "") || 
                      (wordData.image && wordData.image.length > 0 && wordData.image[0] !== "")) && (
                      <div className="media-section">
                        <h3>Visual Examples:</h3>
                        <div className="media-grid">
                          {wordData.media.map((url, index) => (
                            url && url !== "" && (
                              <div key={`video-${index}`} className="media-item">
                                <video controls>
                                  <source src={url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            )
                          ))}
                          {wordData.image.map((url, index) => (
                            url && url !== "" && (
                              <div key={`image-${index}`} className="media-item">
                                <img src={url} alt={`Example of ${wordData.word}`} />
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Memory Tips */}
                    {wordData.mnemonics && wordData.mnemonics.length > 0 && wordData.mnemonics[0] !== "" && (
                      <div className="mnemonics-section">
                        <h3>Memory Tips:</h3>
                        <div className="mnemonics-list">
                          {wordData.mnemonics.map((mnemonic, index) => (
                            mnemonic && (
                              <div key={index} className="mnemonic-item">
                                <span className="mnemonic-icon">💡</span>
                                <p>{mnemonic}</p>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="no-results">
            <p>No words found matching "{searchTerm}"</p>
            <button onClick={() => setSearchTerm('')} className="clear-search-button">
              Clear Search
            </button>
          </div>
        )}
      </div>

      {/* Add Media Popup */}
      {mediaPopup.isOpen && (
        <div className="media-popup-overlay" onClick={() => setMediaPopup({ isOpen: false })}>
          <div className="media-popup-content" onClick={e => e.stopPropagation()}>
            <button className="close-popup" onClick={() => setMediaPopup({ isOpen: false })}>
              ✖
            </button>
            
            {mediaPopup.content.length > 1 && (
              <div className="carousel-controls">
                <button 
                  className="carousel-button"
                  onClick={() => handleCarouselNav('prev')}
                >
                  ◀
                </button>
                <span className="carousel-counter">
                  {mediaPopup.currentIndex + 1} / {mediaPopup.content.length}
                </span>
                <button 
                  className="carousel-button"
                  onClick={() => handleCarouselNav('next')}
                >
                  ▶
                </button>
              </div>
            )}

            <div className="media-container">
              {mediaPopup.type === 'image' ? (
                <img 
                  src={mediaPopup.content[mediaPopup.currentIndex]} 
                  alt="Word visualization" 
                />
              ) : (
                <video 
                  controls 
                  autoPlay
                  src={mediaPopup.content[mediaPopup.currentIndex]}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      )}

      {showDefinitionPopup && definitionData && (
        <div className="popup-container">
          <WordDefinitionPopup
            word={selectedText}
            data={definitionData}
            onClose={() => {
              setShowDefinitionPopup(false);
              setDefinitionData(null);
              setSelectedText('');
            }}
          />
        </div>
      )}
    </div>
  );
}

export default VocabularyPage;