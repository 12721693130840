import React, { useState, useEffect } from 'react';
import { mathQuizData } from '../data/mathQuizData';
import '../styles/MathQuiz.css';

function MathQuiz({ category, onProgress }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const questions = mathQuizData[category] || [];

  useEffect(() => {
    if (showResult) {
      const progress = Math.round((score / questions.length) * 100);
      onProgress(progress);
    }
  }, [showResult, score, questions.length, onProgress]);

  const handleAnswer = () => {
    const correct = selectedAnswer === questions[currentQuestion].answer;
    setIsCorrect(correct);
    setShowFeedback(true);

    if (correct) {
      setScore(score + 1);
    }

    setTimeout(() => {
      setShowFeedback(false);
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedAnswer('');
      } else {
        setShowResult(true);
      }
    }, 1500);
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setSelectedAnswer('');
    setScore(0);
    setShowResult(false);
    setShowFeedback(false);
  };

  if (questions.length === 0) {
    return (
      <div className="quiz-card">
        <p className="no-questions">No quiz questions available for this category</p>
      </div>
    );
  }

  if (showResult) {
    return (
      <div className="quiz-card result">
        <h2>Quiz Result</h2>
        <div className="score-display">
          <div className="score">{score} / {questions.length}</div>
          <div className="percentage">
            {Math.round((score / questions.length) * 100)}%
          </div>
        </div>
        <button className="quiz-button" onClick={restartQuiz}>
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="quiz-card">
      <div className="quiz-header">
        <h2>Question {currentQuestion + 1} of {questions.length}</h2>
        <div className="score-tracker">Score: {score}</div>
      </div>

      <div className="question">
        {questions[currentQuestion].question}
      </div>

      <div className="options">
        {questions[currentQuestion].options.map((option, index) => (
          <button
            key={index}
            className={`option-button ${selectedAnswer === option ? 'selected' : ''} 
              ${showFeedback ? (
                option === questions[currentQuestion].answer ? 'correct' :
                selectedAnswer === option ? 'incorrect' : ''
              ) : ''}`}
            onClick={() => !showFeedback && setSelectedAnswer(option)}
            disabled={showFeedback}
          >
            {option}
          </button>
        ))}
      </div>

      {showFeedback && (
        <div className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}>
          {isCorrect ? 'Correct!' : 'Incorrect!'}
        </div>
      )}

      <button
        className="quiz-button"
        onClick={handleAnswer}
        disabled={!selectedAnswer || showFeedback}
      >
        {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
      </button>
    </div>
  );
}

export default MathQuiz; 