import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Data } from '../data';
import '../styles/PracticePage.css';
import { Tooltip } from '../components/Tooltip';

const PracticePage = () => {
  const [currentWord, setCurrentWord] = useState(null);
  const [userAnswer, setUserAnswer] = useState('');
  const [showAnswer, setShowAnswer] = useState(false);
  const [practiceComplete, setPracticeComplete] = useState(false);
  const [practiceResults, setPracticeResults] = useState([]);
  const [remainingWords, setRemainingWords] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [streak, setStreak] = useState(0);
  const [showHint, setShowHint] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30); // 30 seconds per word

  // Initialize practice session with random words
  useEffect(() => {
    const shuffledWords = [...Data].sort(() => Math.random() - 0.5).slice(0, 10);
    setRemainingWords(shuffledWords);
    setCurrentWord(shuffledWords[0]);
    setStartTime(Date.now());
  }, []);

  // Timer countdown
  useEffect(() => {
    if (!showAnswer && currentWord && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            handleSubmitAnswer(true); // Auto-submit when time runs out
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [currentWord, showAnswer, timeLeft]);

  const getHint = () => {
    return currentWord.synonyms.map(synonym => {
      return `${synonym[0]}${synonym.slice(1).replace(/[a-zA-Z]/g, '_')}`;
    });
  };

  const handleSubmitAnswer = (timeOut = false) => {
    const timeTaken = (Date.now() - startTime) / 1000;
    
    // Clean and normalize the user's answers
    const normalizedUserAnswers = userAnswer
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
      .split(/[\s,]+/)
      .map(word => word.trim())
      .filter(word => word.length > 0);
    
    // Clean and normalize the correct synonyms
    const normalizedSynonyms = currentWord.synonyms.map(syn => 
      syn.toLowerCase()
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
        .trim()
    );

    // Find which answers were correct and incorrect
    const correctAnswers = normalizedUserAnswers.filter(answer => 
      normalizedSynonyms.includes(answer)
    );
    const incorrectAnswers = normalizedUserAnswers.filter(answer => 
      !normalizedSynonyms.includes(answer)
    );

    // Consider the response correct if at least one answer is correct
    const isCorrect = correctAnswers.length > 0;

    // Update streak
    if (isCorrect) {
      setStreak(prev => prev + 1);
    } else {
      setStreak(0);
    }

    const result = {
      word: currentWord.word,
      userAnswer: timeOut ? "Time's up!" : userAnswer.trim(),
      correct: isCorrect,
      correctAnswers,
      incorrectAnswers,
      timeTaken,
      definition: currentWord.definition,
      correctSynonyms: currentWord.synonyms,
      usedHint: showHint,
      timeOut
    };

    setPracticeResults(prev => [...prev, result]);
    setShowAnswer(true);
  };

  const handleNextWord = () => {
    const newRemainingWords = remainingWords.slice(1);
    setRemainingWords(newRemainingWords);
    
    if (newRemainingWords.length > 0) {
      setCurrentWord(newRemainingWords[0]);
      setUserAnswer('');
      setShowAnswer(false);
      setShowHint(false);
      setTimeLeft(30);
      setStartTime(Date.now());
    } else {
      setPracticeComplete(true);
    }
  };

  const calculateScore = () => {
    const correctAnswers = practiceResults.filter(result => result.correct).length;
    const totalScore = practiceResults.reduce((acc, result) => {
      let points = result.correct ? 10 : 0;
      if (result.correct && !result.usedHint) points += 5; // Bonus for not using hint
      if (result.correct && result.timeTaken < 10) points += 5; // Speed bonus
      return acc + points;
    }, 0);
    return {
      percentage: Math.round((correctAnswers / practiceResults.length) * 100),
      points: totalScore
    };
  };

  if (practiceComplete) {
    const score = calculateScore();
    return (
      <div className="practice-page">
        <div className="practice-content">
          <header className="practice-header">
            <Link to="/progress" className="back-button">
              <span>←</span> Back to Progress
            </Link>
            <h1>Practice Complete!</h1>
          </header>

          <div className="practice-results">
            <div className="results-summary">
              <h2>Your Results</h2>
              <div className="score-display">
                <div className="score-percentage">{score.percentage}%</div>
                <div className="score-points">{score.points} Points</div>
              </div>
              <p>You completed {practiceResults.length} words</p>
              <p>Longest streak: {Math.max(...practiceResults.map((_, i) => 
                practiceResults.slice(0, i + 1).filter(r => r.correct).length
              ))}</p>
            </div>

            <div className="results-details">
              {practiceResults.map((result, index) => (
                <div key={index} className={`result-item ${result.correct ? 'correct' : 'incorrect'}`}>
                  <div className="result-header">
                    <h3>{result.word}</h3>
                    <div className="result-stats">
                      {result.usedHint && <span className="hint-used">Hint Used</span>}
                      <span className="time-taken">{result.timeTaken.toFixed(1)}s</span>
                    </div>
                  </div>
                  <p className="definition">{result.definition}</p>
                  <div className="answer-details">
                    <p>Your answer: <span className="user-answer">{result.userAnswer}</span></p>
                    <p>Correct synonyms: {result.correctSynonyms.join(', ')}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="practice-actions">
              <button 
                className="practice-again-button"
                onClick={() => window.location.reload()}
              >
                Practice Again
              </button>
              <Link to="/progress" className="return-button">
                Return to Progress
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="practice-page">
      <div className="practice-content">
        <header className="practice-header">
          <Link to="/progress" className="back-button">
            <span>←</span> Back to Progress
          </Link>
          <h1>Practice Session</h1>
        </header>

        {currentWord && (
          <div className="practice-question">
            <div className="practice-stats">
              <div className="word-progress">
                Word {practiceResults.length + 1} of {practiceResults.length + remainingWords.length}
              </div>
              <div className="streak-counter">
                Streak: {streak} 🔥
              </div>
              <div className="timer">
                Time: {timeLeft}s
              </div>
            </div>

            <h2 className="practice-word">{currentWord.word}</h2>
            <p className="word-definition">{currentWord.definition}</p>

            <div className="answer-section">
              {showHint && (
                <div className="hint">
                  <div className="hint-label">Hints:</div>
                  <div className="hint-words">
                    {getHint().map((hint, index) => (
                      <span key={index} className="hint-word">{hint}</span>
                    ))}
                  </div>
                </div>
              )}
              
              <div className="input-container">
                <input
                  type="text"
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  placeholder="Enter synonyms separated by commas..."
                  disabled={showAnswer}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && userAnswer.trim() && !showAnswer) {
                      handleSubmitAnswer();
                    }
                  }}
                  autoFocus
                />
                <Tooltip 
                  content={
                    <div className="answer-tooltip">
                      <p>Enter multiple synonyms separated by commas</p>
                      <p>Example: wall, barrier, fence</p>
                      <p>Case and punctuation don't matter</p>
                    </div>
                  }
                />
              </div>

              <div className="button-group">
                {!showAnswer ? (
                  <>
                    <button 
                      className="submit-answer-button"
                      onClick={() => handleSubmitAnswer()}
                      disabled={!userAnswer.trim()}
                    >
                      Submit Answer
                    </button>
                    <button 
                      className="hint-button"
                      onClick={() => setShowHint(true)}
                      disabled={showHint}
                    >
                      Get Hint
                    </button>
                  </>
                ) : (
                  <button 
                    className="next-word-button"
                    onClick={handleNextWord}
                  >
                    Next Word
                  </button>
                )}
              </div>
            </div>

            {showAnswer && (
              <div className={`answer-feedback ${
                currentWord.synonyms.some(syn => 
                  userAnswer.toLowerCase()
                    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
                    .split(/[\s,]+/)
                    .map(word => word.trim())
                    .filter(word => word.length > 0)
                    .includes(syn.toLowerCase())
                ) ? 'correct' : 'incorrect'
              }`}>
                <h3>
                  {currentWord.synonyms.some(syn => 
                    userAnswer.toLowerCase()
                      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
                      .split(/[\s,]+/)
                      .map(word => word.trim())
                      .filter(word => word.length > 0)
                      .includes(syn.toLowerCase())
                  ) ? '✓ Correct!' : '✗ Incorrect'}
                </h3>
                <div className="answer-details">
                  {!practiceResults[practiceResults.length - 1]?.timeOut && userAnswer.trim() && (
                    <div className="user-answers">
                      <p>Your answers:</p>
                      <div className="answer-chips">
                        {userAnswer.split(/[\s,]+/)
                          .map(word => word.trim())
                          .filter(word => word.length > 0)
                          .map((answer, index) => (
                            <span key={index} className={`answer-chip ${
                              currentWord.synonyms.some(syn => 
                                syn.toLowerCase() === answer.toLowerCase()
                                  .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
                              ) ? 'correct' : 'incorrect'
                            }`}>
                              {answer}
                            </span>
                          ))}
                      </div>
                    </div>
                  )}
                  <p className="correct-synonyms">
                    <span>All correct synonyms:</span> {currentWord.synonyms.join(', ')}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticePage;