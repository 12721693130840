import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/MathApp.css';
import MathFlashcards from './MathFlashcards';
import MathQuiz from './MathQuiz';
import MathCalculator from './MathCalculator';
import MathReference from './MathReference';
import ProgressModal from './ProgressModal';

const categories = ['Distance', 'Weight', 'Counting', 'Liquid', 'Dry', 'Time'];

function MathApp() {
  const [activeCategory, setActiveCategory] = useState('Distance');
  const [activeTab, setActiveTab] = useState('flashcards');
  const [progress, setProgress] = useState({
    Distance: 0,
    Weight: 0,
    Counting: 0,
    Liquid: 0,
    Dry: 0,
    Time: 0
  });
  const [showProgress, setShowProgress] = useState(false);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleProgressUpdate = (category, value) => {
    setProgress(prev => ({ ...prev, [category]: value }));
  };

  const getCategoryIcon = (category) => {
    const icons = {
      Distance: "📏",
      Weight: "⚖️",
      Counting: "🔢",
      Liquid: "💧",
      Dry: "🌾",
      Time: "⏰"
    };
    return icons[category] || "📊";
  };

  return (
    <div className="math-app">
      <div className="math-header">
        <Link to="/" className="back-button">
          <span>←</span> Back
        </Link>
        <h1>
          <span className="math-title-icon">🧮</span>
          Measurement Master
        </h1>
        <button 
          className="progress-button"
          onClick={() => setShowProgress(true)}
        >
          <span className="progress-icon">📊</span>
          View Progress
        </button>
      </div>
      
      <div className="tabs">
        <div className="tabs-list">
          {[
            { id: 'flashcards', label: 'Flashcards', icon: '🎴' },
            { id: 'quiz', label: 'Quiz', icon: '📝' },
            { id: 'calculator', label: 'Calculator', icon: '🔢' },
            { id: 'reference', label: 'Reference', icon: '📚' }
          ].map(tab => (
            <button 
              key={tab.id}
              className={`tab ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => handleTabChange(tab.id)}
            >
              <span className="tab-icon">{tab.icon}</span>
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      <div className="categories">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => handleCategoryChange(category)}
            className={`category-button ${activeCategory === category ? 'active' : ''}`}
          >
            <span className="category-icon">{getCategoryIcon(category)}</span>
            {category}
          </button>
        ))}
      </div>

      <div className="tab-content">
        {activeTab === 'flashcards' && (
          <div className="flashcards">
            <MathFlashcards category={activeCategory} />
          </div>
        )}
        {activeTab === 'quiz' && (
          <div className="quiz">
            <MathQuiz 
              category={activeCategory} 
              onProgress={(value) => handleProgressUpdate(activeCategory, value)} 
            />
          </div>
        )}
        {activeTab === 'calculator' && (
          <div className="calculator">
            <MathCalculator category={activeCategory} />
          </div>
        )}
        {activeTab === 'reference' && (
          <div className="reference">
            <MathReference category={activeCategory} />
          </div>
        )}
      </div>

      <ProgressModal 
        progress={progress}
        isOpen={showProgress}
        onClose={() => setShowProgress(false)}
      />
    </div>
  );
}

export default MathApp;