import React, { useState } from 'react';
import '../styles/MathReference.css';

const referenceData = {
  Distance: [
    { from: 'feet', to: 'inches', factor: 12, icon: '📏', tip: 'Remember: 1 foot = 12 inches, like a ruler!' },
    { from: 'yards', to: 'feet', factor: 3, icon: '🏃', tip: 'A yard is about a large step. 3 feet in a yard.' },
    { from: 'miles', to: 'feet', factor: 5280, icon: '🚗', tip: '5,280 feet in a mile. Think "5 tomatoes" (5-2-8-0)' },
    { from: 'kilometers', to: 'meters', factor: 1000, icon: '🌍', tip: 'Kilo means 1000. 1 kilometer = 1000 meters' },
    { from: 'meters', to: 'centimeters', factor: 100, icon: '📐', tip: 'Centi means 100. 1 meter = 100 centimeters' },
  ],
  Weight: [
    { from: 'pounds', to: 'ounces', factor: 16, icon: '⚖️', tip: '16 ounces in a pound. Think "Sweet 16"' },
    { from: 'tons', to: 'pounds', factor: 2000, icon: '🏋️', tip: '2000 pounds in a ton. 2 kilo-pounds!' },
    { from: 'kilograms', to: 'grams', factor: 1000, icon: '🍎', tip: 'Kilo means 1000. 1 kilogram = 1000 grams' },
  ],
  Counting: [
    { from: 'dozen', to: 'units', factor: 12, icon: '🥚', tip: 'A dozen eggs is 12. Easy to remember!' },
    { from: 'gross', to: 'dozen', factor: 12, icon: '📦', tip: 'A gross is a dozen dozens. 12 x 12 = 144' },
  ],
  Liquid: [
    { 
      from: 'cup', 
      to: 'visual', 
      factor: 1, 
      icon: '☕', 
      tip: '1 cup = 8 fluid ounces',
      visual: true,
      squares: [['Cup']],
      color: '#00d2ff',
      labels: {
        title: 'Cup',
        description: '1 Cup = 8 fluid ounces'
      }
    },
    { 
      from: 'pint', 
      to: 'visual', 
      factor: 2, 
      icon: '🍺', 
      tip: '1 pint = 2 cups',
      visual: true,
      squares: [
        ['Pint', 'Pint']
      ],
      color: '#00d2ff',
      labels: {
        title: 'Pint',
        description: '1 Pint = 2 Cups'
      }
    },
    { 
      from: 'quart', 
      to: 'visual', 
      factor: 4, 
      icon: '🥛', 
      tip: '1 quart = 2 pints = 4 cups',
      visual: true,
      mainView: {
        title: 'Quart',
        squares: [
          ['Cup', 'Cup'],
          ['Cup', 'Cup']
        ]
      },
      alternateView: {
        title: 'Quart',
        squares: [
          ['Cup', 'Pint'],
          ['Cup', '']
        ],
        colors: {
          Cup: '#00d2ff',
          Pint: '#ffd700'
        }
      },
      labels: {
        description: '1 Quart = 4 Cups = 2 Pints'
      }
    },
    { 
      from: 'gallon', 
      to: 'visual', 
      factor: 16, 
      icon: '🚰', 
      tip: '1 gallon = 4 quarts = 8 pints = 16 cups',
      visual: true,
      mainView: {
        title: 'Gallon',
        squares: [
          ['', '', '', ''],
          ['', '', '', ''],
          ['', '', '', ''],
          ['', '', '', '']
        ],
        dottedLines: true
      },
      alternateView: {
        title: 'Gallon',
        squares: [
          ['Cup', 'Cup', 'Quart', ''],
          ['Cup', 'Cup', '', ''],
          ['Pint', 'Pint', 'Quart', ''],
          ['', '', '', '']
        ],
        colors: {
          Cup: '#00d2ff',
          Pint: '#90EE90',
          Quart: '#ffd700'
        }
      },
      labels: {
        description: '1 Gallon = 16 Cups = 8 Pints = 4 Quarts'
      }
    }
  ],
  Dry: [
    { from: 'pints', to: 'quarts', factor: 0.5, icon: '🌾', tip: '2 dry pints = 1 dry quart' },
    { from: 'quarts', to: 'pecks', factor: 0.125, icon: '🥔', tip: '8 quarts = 1 peck. Think "8-pack"' },
    { from: 'pecks', to: 'bushels', factor: 0.25, icon: '🌽', tip: '4 pecks = 1 bushel. Quarter of a bushel is a peck.' },
  ],
  Time: [
    { from: 'minutes', to: 'seconds', factor: 60, icon: '⏱️', tip: '60 seconds in a minute. Like the hands of a clock!' },
    { from: 'hours', to: 'minutes', factor: 60, icon: '⏰', tip: '60 minutes in an hour. Two rotations of the clock!' },
    { from: 'days', to: 'hours', factor: 24, icon: '📅', tip: '24 hours in a day. Think of the 24-hour clock.' },
    { from: 'weeks', to: 'days', factor: 7, icon: '🗓️', tip: '7 days in a week. 7 colors in a rainbow!' },
  ],
};

function MathReference({ category }) {
  const conversions = referenceData[category] || [];
  const [interactiveValues, setInteractiveValues] = useState(
    Object.fromEntries(conversions.map(c => [c.from, '1']))
  );
  const [showTip, setShowTip] = useState(null);

  const handleInputChange = (from, value) => {
    setInteractiveValues(prev => ({ ...prev, [from]: value }));
  };

  if (conversions.length === 0) {
    return (
      <div className="reference-card">
        <p className="no-data">No reference data available for this category</p>
      </div>
    );
  }

  return (
    <div className="reference-card">
      <div className="reference-header">
        <h2>{category} Conversion Reference</h2>
      </div>

      <div className="reference-grid">
        {conversions.map((conversion, index) => (
          <div key={index} className="conversion-card">
            <div className="conversion-icon" 
                 onMouseEnter={() => setShowTip(index)}
                 onMouseLeave={() => setShowTip(null)}>
              {conversion.icon}
              {showTip === index && (
                <div className="tip-tooltip">{conversion.tip}</div>
              )}
            </div>
            
            <div className="conversion-content">
              <div className="unit-row">
                <span className="unit-label">{conversion.from}</span>
                <input
                  type="number"
                  value={interactiveValues[conversion.from]}
                  onChange={(e) => handleInputChange(conversion.from, e.target.value)}
                  className="unit-input"
                  min="0"
                />
              </div>

              <div className="arrow">↓</div>

              <div className="unit-row">
                <span className="unit-label">{conversion.to}</span>
                <span className="unit-result">
                  {(parseFloat(interactiveValues[conversion.from] || 0) * conversion.factor).toFixed(2)}
                </span>
              </div>

              <div className="conversion-formula">
                1 {conversion.from} = {conversion.factor} {conversion.to}
              </div>
            </div>

            {conversion.visual && (
              <div className="visual-conversion">
                <h3 className="visual-title">{conversion.labels.title}</h3>
                <div className="visual-container">
                  {/* Main View */}
                  {conversion.mainView ? (
                    <>
                      <h4 className="view-title">{conversion.mainView.title}</h4>
                      <div className={`square-grid ${conversion.from}`}>
                        {conversion.mainView.squares.map((row, rowIndex) => (
                          <div key={rowIndex} className="square-row">
                            {row.map((cell, cellIndex) => (
                              <div 
                                key={cellIndex} 
                                className={`square-cell ${cell.toLowerCase()}`}
                                style={{ backgroundColor: '#00d2ff' }}
                              >
                                {cell}
                              </div>
                            ))}
                          </div>
                        ))}
                        {conversion.mainView.dottedLines && (
                          <div className="dotted-lines">
                            <div className="vertical-line"></div>
                            <div className="horizontal-line"></div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={`square-grid ${conversion.from}`}>
                      {conversion.squares.map((row, rowIndex) => (
                        <div key={rowIndex} className="square-row">
                          {row.map((cell, cellIndex) => (
                            <div 
                              key={cellIndex} 
                              className={`square-cell ${cell.toLowerCase()}`}
                              style={{ backgroundColor: '#00d2ff' }}
                            >
                              {cell}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Alternate View */}
                  {conversion.alternateView && (
                    <>
                      <h4 className="view-title">{conversion.alternateView.title}</h4>
                      <div className={`square-grid ${conversion.from} alternate`}>
                        {conversion.alternateView.squares.map((row, rowIndex) => (
                          <div key={rowIndex} className="square-row">
                            {row.map((cell, cellIndex) => (
                              <div 
                                key={cellIndex} 
                                className={`square-cell ${cell.toLowerCase()}`}
                                style={{ 
                                  backgroundColor: cell ? conversion.alternateView.colors[cell] : 'transparent'
                                }}
                              >
                                {cell}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <p className="visual-description">{conversion.labels.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MathReference; 