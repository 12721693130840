import React, { useState, useEffect } from 'react';
import { mathFlashcardsData } from '../data/mathFlashcardsData';
import '../styles/MathFlashcards.css';

function MathFlashcards({ category }) {
  const [currentCard, setCurrentCard] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    // Reset state when category changes
    setCards(mathFlashcardsData[category] || []);
    setCurrentCard(0);
    setIsFlipped(false);
  }, [category]);

  const nextCard = () => {
    setCurrentCard((prev) => (prev + 1) % cards.length);
    setIsFlipped(false);
  };

  const prevCard = () => {
    setCurrentCard((prev) => (prev - 1 + cards.length) % cards.length);
    setIsFlipped(false);
  };

  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  if (cards.length === 0) {
    return (
      <div className="flashcards-container">
        <p className="no-cards">No flashcards available for this category</p>
      </div>
    );
  }

  return (
    <div className="flashcards-container">
      <div className="card-progress">
        Card {currentCard + 1} of {cards.length}
      </div>
      
      <div 
        className={`flashcard ${isFlipped ? 'flipped' : ''}`}
        onClick={flipCard}
      >
        <div className="flashcard-inner">
          <div className="flashcard-front">
            {cards[currentCard].front}
          </div>
          <div className="flashcard-back">
            {cards[currentCard].back}
          </div>
        </div>
      </div>

      <div className="flashcard-controls">
        <button 
          className="control-button"
          onClick={prevCard}
        >
          Previous
        </button>
        <button 
          className="control-button"
          onClick={nextCard}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default MathFlashcards; 