import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/QuizResults.css';

function QuizResultsPage({ results }) {
  const navigate = useNavigate();

  const handleDownloadPDF = () => {
    // Move existing PDF generation logic here
  };

  return (
    <div className="quiz-results-page">
      <div className="header-left">
        <Link to="/" className="back-button">
          <span>←</span> Main Menu
        </Link>
      </div>
      
      <div className="results-container">
        <h2 className="results-title">📝 Quiz Results</h2>
        <div className="results-list">
          {Object.entries(results || {}).map(([word, resultData]) => {
            const { correct = [], incorrect = [], times = [] } = resultData;
            const totalAttempts = correct.length + incorrect.length;
            const score = totalAttempts > 0 ? (correct.length / totalAttempts) * 100 : 0;
            
            return (
              <div key={word} className="result-card">
                <div className="result-header">
                  <h3 className="word-title">
                    <span className="word-emoji">📚</span> {resultData.word || word}
                  </h3>
                  <div className="score-badge" style={{
                    backgroundColor: score >= 70 ? 'rgba(74, 222, 128, 0.2)' : 'rgba(255, 68, 68, 0.2)',
                    color: score >= 70 ? '#4ade80' : '#ff4444'
                  }}>
                    {Math.round(score)}% correct
                  </div>
                </div>
                
                <p className="word-definition">
                  <span className="definition-emoji">💭</span> {resultData.definition}
                </p>

                <div className="synonyms-section">
                  <h4 className="synonyms-title">
                    <span className="synonyms-icon">🔤</span> Synonyms
                  </h4>
                  <div className="synonyms-container">
                    <div className="synonyms-grid">
                      {resultData.allSynonyms.map((synonym) => (
                        <div key={synonym} className="synonym-wrapper">
                          <span 
                            className={`synonym-tag ${
                              correct.includes(synonym)
                                ? 'correct'
                                : incorrect.includes(synonym)
                                ? 'incorrect'
                                : ''
                            }`}
                          >
                            {synonym}
                            {correct.includes(synonym) && (
                              <span className="status-icon">✓</span>
                            )}
                            {incorrect.includes(synonym) && (
                              <span className="status-icon">✗</span>
                            )}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="time-info">
                  <span className="time-emoji">⏱️</span> Average response time: {
                    times.length > 0 
                      ? (times.reduce((a, b) => a + b, 0) / times.length).toFixed(2) 
                      : '0.00'
                  } seconds
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default QuizResultsPage; 