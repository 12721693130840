export const mathFlashcardsData = {
  Distance: [
    { front: "1 foot (ft.)", back: "12 inches (in.)" },
    { front: "1 yard (yd.)", back: "3 feet" },
    { front: "1 mile (mi.)", back: "5280 feet or 1760 yd." },
  ],
  Weight: [
    { front: "1 pound (lb.)", back: "16 ounces (oz.)" },
    { front: "1 ton (T.)", back: "2000 pounds" },
  ],
  Counting: [
    { front: "1 dozen (doz.)", back: "12 units" },
    { front: "1 gross (gr.)", back: "12 dozen or 144 units" },
  ],
  Liquid: [
    { front: "1 cup (c.)", back: "8 ounces" },
    { front: "1 pint (pt.)", back: "2 cups" },
    { front: "1 quart (qt.)", back: "2 pints or 4 cups" },
    { front: "1 gallon (gal.)", back: "4 quarts or 8 pints" },
  ],
  Dry: [
    { front: "1 quart", back: "2 pints" },
    { front: "1 peck", back: "8 quarts" },
    { front: "1 bushel (bu.)", back: "4 pecks" },
  ],
  Time: [
    { front: "1 minute (min.)", back: "60 seconds (sec.)" },
    { front: "1 hour (hr.)", back: "60 minutes" },
    { front: "1 day", back: "24 hours" },
    { front: "1 week", back: "7 days" },
  ],
}; 