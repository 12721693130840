import React, { useState, useEffect } from 'react';

import QuizContainer from './components/QuizContainer';

import ResultsContainer from './components/ResultsContainer';

import { Data } from './data';

import { 

  calculateNextReview, 

  checkDueForReview, 

  getPriorityScore 

} from './utils/spaceRepetition';

import Cookies from 'js-cookie';

import './App.css';

import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';

import ProgressPage from './components/ProgressPage';

import { ThemeProvider } from './context/ThemeContext';

import PracticePage from './components/PracticePage';

import QuizResultsPage from './components/QuizResultsPage';

import WordKnowledgePage from './components/WordKnowledgePage';

import VocabularyPage from './components/VocabularyPage';

import MathApp from './components/MathApp';



export function App() {

  const [sessionId] = useState(() => {

    const savedSession = Cookies.get('synonymQuizSession');

    return savedSession || Math.random().toString(36).substring(2) + Date.now().toString(36);

  });



  const [userStats, setUserStats] = useState(() => {

    const savedStats = Cookies.get(`stats_${sessionId}`);

    return savedStats ? JSON.parse(savedStats) : {

      totalQuizzes: 0,

      averageScore: 0,

      wordsLearned: [],

      weakWords: [],

      quizHistory: [],

      categoryPerformance: {},

      timeStats: { averageResponseTime: 0 }

    };

  });



  // Add state for quiz results

  const [quizResults, setQuizResults] = useState(null);



  // Save stats to cookie whenever they change

  useEffect(() => {

    Cookies.set(`stats_${sessionId}`, JSON.stringify(userStats), { expires: 30 });

  }, [userStats, sessionId]);



  // Add state for quiz setup

  const [quizSetup, setQuizSetup] = useState({

    selectedAlphabets: ['all'],

    selectedWordCount: 5,

    filteredWords: []

  });



  const [customWords, setCustomWords] = useState(() => {

    const saved = localStorage.getItem('customWords');

    return saved ? JSON.parse(saved) : [];

  });



  const [excludedWords, setExcludedWords] = useState(() => {

    const saved = localStorage.getItem('excludedWords');

    return saved ? JSON.parse(saved) : {};

  });



  // Add effect to sync with localStorage

  useEffect(() => {

    const handleStorageChange = () => {

      const savedCustomWords = localStorage.getItem('customWords');

      const savedExcludedWords = localStorage.getItem('excludedWords');

      

      if (savedCustomWords) {

        setCustomWords(JSON.parse(savedCustomWords));

      }

      if (savedExcludedWords) {

        setExcludedWords(JSON.parse(savedExcludedWords));

      }

    };



    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);

  }, []);



  return (

    <ThemeProvider>

      <Router>

        <Routes>

          <Route 

            path="/" 

            element={

              <AppContent 

                userStats={userStats} 

                setUserStats={setUserStats} 

                sessionId={sessionId} 

                setQuizResults={setQuizResults} 

                quizSetup={quizSetup} 

                setQuizSetup={setQuizSetup} 

                customWords={customWords} 

                excludedWords={excludedWords} 

                setCustomWords={setCustomWords} 

                setExcludedWords={setExcludedWords} 

              />

            } 

          />

          <Route 

            path="/progress" 

            element={

              <ProgressPage userStats={userStats} />

            } 

          />

          <Route 

            path="/practice" 

            element={<PracticePage />} 

          />

          <Route 

            path="/results" 

            element={<QuizResultsPage results={quizResults} />} 

          />

          <Route 

            path="/word-knowledge" 

            element={

              <WordKnowledgePage 

                quizSetup={quizSetup} 

                setQuizResults={setQuizResults} 

                setUserStats={setUserStats} 

              />

            } 

          />

          <Route 

            path="/vocabulary" 

            element={

              <VocabularyPage 

                userStats={userStats}

                setUserStats={setUserStats}

                customWords={customWords}

                excludedWords={excludedWords}

              />

            } 

          />

          <Route 

            path="/math" 

            element={<MathApp />} 

          />

        </Routes>

      </Router>

    </ThemeProvider>

  );

}

function AppContent({ 

  userStats, 

  setUserStats, 

  sessionId, 

  setQuizResults,

  quizSetup,

  setQuizSetup,

  customWords,

  excludedWords,

  setCustomWords,

  setExcludedWords

}) {

  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);



  const [quizData, setQuizData] = useState([]);



  const [selectedWordCount, setSelectedWordCount] = useState(5);

  const [quizStarted, setQuizStarted] = useState(false);

  const [quizCompleted, setQuizCompleted] = useState(false);

  const [results, setResults] = useState({});

  const [selectedAlphabets, setSelectedAlphabets] = useState(['all']);

  const [showStats, setShowStats] = useState(false);

  const [showResetConfirm, setShowResetConfirm] = useState(false);

  const [wordProgress, setWordProgress] = useState(() => {

    const savedProgress = Cookies.get(`progress_${sessionId}`);

    return savedProgress ? JSON.parse(savedProgress) : {};

  });



  // Save session ID to cookie

  useEffect(() => {

    Cookies.set('synonymQuizSession', sessionId, { expires: 30 }); // Expires in 30 days

  }, [sessionId]);



  // Save progress to cookie whenever it changes

  useEffect(() => {

    Cookies.set(`progress_${sessionId}`, JSON.stringify(wordProgress), { expires: 30 });

  }, [wordProgress, sessionId]);



  // Create alphabet categories

  const alphabetCategories = ['all', ...Array.from('abcdefghijklmnopqrstuvwxyz')];

  

  const getFilteredWords = () => {

    const masteredWords = new Set();

    const masteredSynonyms = new Map(); // word -> Set of mastered synonyms



    // Initialize masteredSynonyms map with both Data and customWords

    [...Data, ...customWords].forEach(word => {

      masteredSynonyms.set(word.word, new Set());

    });



    // Get mastered words and synonyms from quiz history

    userStats.quizHistory.forEach(quiz => {

      Object.entries(quiz.wordResults || {}).forEach(([word, result]) => {

        if (result.allSynonyms.every(synonym => result.correct.includes(synonym))) {

          masteredWords.add(word);

        } else {

          result.correct.forEach(synonym => {

            if (masteredSynonyms.has(word)) {

              masteredSynonyms.get(word).add(synonym);

            }

          });

        }

      });

    });



    // Filter words based on selected letters

    let filteredWords = [...Data, ...customWords];

    if (!selectedAlphabets.includes('all')) {

      filteredWords = filteredWords.filter(word => 

        selectedAlphabets.some(letter => 

          word.word.toLowerCase().startsWith(letter.toLowerCase())

        )

      );

    }



    // Apply exclusions and mastery filters

    return filteredWords

      .filter(word => 

        // Remove fully excluded words and fully mastered words

        !excludedWords[word.word]?.fully && 

        !masteredWords.has(word.word)

      )

      .map(word => ({

        ...word,

        synonyms: word.synonyms.filter(synonym => 

          // Remove excluded and mastered synonyms

          !excludedWords[word.word]?.synonyms?.includes(synonym) &&

          !masteredSynonyms.get(word.word)?.has(synonym)

        )

      }))

      .filter(word => word.synonyms.length > 0); // Only keep words with remaining synonyms

  };



  const handleAlphabetSelect = (letter) => {

    let newSelection;

    if (letter === 'all') {

      newSelection = ['all'];

    } else {

      newSelection = selectedAlphabets.includes('all') 

        ? [letter] 

        : selectedAlphabets.includes(letter)

          ? selectedAlphabets.filter(l => l !== letter)

          : [...selectedAlphabets, letter];

      

      if (newSelection.length === 0) {

        newSelection = ['all'];

      }

    }

    

    setSelectedAlphabets(newSelection);

    

    // Update quiz setup with new selection

    const filteredWords = getFilteredWords();

    setQuizSetup(prev => ({

      ...prev,

      selectedAlphabets: newSelection,

      filteredWords

    }));

    

    // Update word count based on available words

    const availableWords = filteredWords.length;

    setSelectedWordCount(Math.min(selectedWordCount, availableWords || 1));

    setQuizSetup(prev => ({

      ...prev,

      selectedWordCount: Math.min(selectedWordCount, availableWords || 1)

    }));

  };



  useEffect(() => {

    const prepareQuizData = () => {

      const filteredWords = getFilteredWords();

      const actualWordCount = Math.min(selectedWordCount, filteredWords.length);

      

      if (actualWordCount === 0) {

        return [];

      }



      // Select words for the quiz

      const selectedWords = [...filteredWords]

        .sort(() => 0.5 - Math.random())

        .slice(0, actualWordCount);



      // Create questions for each word's synonyms

      const questions = selectedWords.flatMap(word => {

        // Create a question for each synonym

        return word.synonyms.map(synonym => {

          // Get wrong choices from other words' synonyms

          const otherSynonyms = Data

            .filter(item => item.word !== word.word) // Exclude current word

            .flatMap(item => item.synonyms)

            .filter(syn => !word.synonyms.includes(syn)); // Exclude current word's synonyms



          // Shuffle and get 3 random wrong answers

          const wrongAnswers = [...otherSynonyms]

            .sort(() => 0.5 - Math.random())

            .slice(0, 3);



          // Combine with correct answer and shuffle

          const choices = [synonym, ...wrongAnswers].sort(() => 0.5 - Math.random());



          return {

            word: word.word,

            correctSynonym: synonym,

            definition: word.definition,

            choices: choices,

            allSynonyms: word.synonyms

          };

        });

      });



      // Shuffle all questions

      return questions.sort(() => 0.5 - Math.random());

    };



    setQuizData(prepareQuizData());

  }, [selectedWordCount, selectedAlphabets, wordProgress]);



  const getChoices = (correctSynonym, wordSynonyms, selectedData) => {

    // Get all synonyms from other words (excluding the current word's synonyms)

    const otherSynonyms = Data

      .filter(item => !item.synonyms.includes(correctSynonym)) // Exclude words that have the correct synonym

      .flatMap(item => item.synonyms)

      .filter(syn => !wordSynonyms.includes(syn)); // Exclude all synonyms of current word



    // Shuffle and get 3 random wrong answers

    const shuffledSynonyms = [...otherSynonyms].sort(() => 0.5 - Math.random());

    const wrongAnswers = shuffledSynonyms.slice(0, 3);



    // Combine correct answer with wrong answers and shuffle

    return [correctSynonym, ...wrongAnswers].sort(() => 0.5 - Math.random());

  };



  const handleStartQuiz = () => {

    const filteredWords = getFilteredWords();

    if (filteredWords.length === 0) {

      alert(`No unmastered words available${

        !selectedAlphabets.includes('all') 

          ? ` starting with '${selectedAlphabets.join(', ')}'` 

          : ''

      }`);

      return;

    }



    // Update quiz setup with filtered words

    setQuizSetup(prev => ({

      ...prev,

      filteredWords

    }));



    // Navigate to word knowledge page

    navigate('/word-knowledge');

  };



  const handleQuizComplete = (results) => {

    setQuizCompleted(true);

    

    // Format and process results

    const formattedResults = results.reduce((acc, result) => {

      if (!acc[result.word]) {

        acc[result.word] = {

          correct: [],

          incorrect: [],

          times: [],

          definition: result.definition,

          allSynonyms: result.allSynonyms

        };

      }



      if (result.userAnswer === result.correctSynonym) {

        acc[result.word].correct.push(result.userAnswer);

      } else {

        acc[result.word].incorrect.push(result.userAnswer);

      }

      acc[result.word].times.push(result.timeTaken);



      return acc;

    }, {});



    // Set the results in the parent component

    setQuizResults(formattedResults);



    // Update user stats

    // Calculate overall quiz statistics

    const totalCorrect = Object.values(formattedResults).reduce(

      (sum, { correct }) => sum + correct.length,

      0

    );

    const totalAttempts = Object.values(formattedResults).reduce(

      (sum, { correct, incorrect }) => sum + correct.length + incorrect.length,

      0

    );

    const score = Math.round((totalCorrect / totalAttempts) * 100);

    const avgResponseTime = Object.values(formattedResults).reduce(

      (sum, { times }) => sum + times.reduce((a, b) => a + b, 0) / times.length,

      0

    ) / Object.keys(formattedResults).length;



    // Create a quiz history entry

    const quizHistoryEntry = {

      date: new Date().toISOString(),

      score,

      avgResponseTime,

      wordResults: formattedResults,

      wordsAttempted: Object.keys(formattedResults).length

    };



    // Update user statistics with the new quiz history

    setUserStats(prev => ({

      ...prev,

      totalQuizzes: prev.totalQuizzes + 1,

      averageScore: Math.round(

        (prev.averageScore * prev.totalQuizzes + score) / (prev.totalQuizzes + 1)

      ),

      wordsLearned: [...new Set([...prev.wordsLearned, ...Object.keys(formattedResults)])],

      quizHistory: [...prev.quizHistory, quizHistoryEntry],

      categoryPerformance: {

        ...prev.categoryPerformance,

        [selectedAlphabets.join(', ')] : score

      },

      timeStats: {

        averageResponseTime: Math.round(

          ((prev.timeStats.averageResponseTime * prev.totalQuizzes) + avgResponseTime) /

          (prev.totalQuizzes + 1)

        )

      }

    }));

  };



  const handleRetakeQuiz = () => {

    setQuizStarted(false);

    setQuizCompleted(false);

    setResults({});

  };



  const handleResetStats = () => {

    // Reset user stats
    setUserStats({
      totalQuizzes: 0,
      averageScore: 0,
      wordsLearned: [],
      weakWords: [],
      quizHistory: [],
      categoryPerformance: {},
      timeStats: { averageResponseTime: 0 }
    });

    // Reset word progress
    setWordProgress({});

    // Reset excluded words
    setExcludedWords({});
    localStorage.removeItem('excludedWords');

    // Reset custom words
    setCustomWords([]);
    localStorage.removeItem('customWords');

    // Reset quiz setup
    setQuizSetup({
      selectedAlphabets: ['all'],
      selectedWordCount: 5,
      filteredWords: []
    });

    // Reset selected word count and alphabets
    setSelectedWordCount(5);
    setSelectedAlphabets(['all']);

    // Clear all relevant cookies
    Cookies.remove(`stats_${sessionId}`);
    Cookies.remove(`progress_${sessionId}`);
    Cookies.remove('synonymQuizSession');

    // Close the confirmation modal
    setShowResetConfirm(false);

    // Optional: Show confirmation message
    alert('All progress has been reset successfully!');
  };



  const getAvailableWords = () => {

    const filteredWords = getFilteredWords();

    const selectedWords = filteredWords.slice(0, selectedWordCount);

    

    const totalSynonyms = selectedWords.reduce((sum, word) => sum + word.synonyms.length, 0);

    

    return {

      wordCount: selectedWords.length,

      synonymCount: totalSynonyms,

      totalAvailableWords: filteredWords.length

    };

  };



  const handleWordCountChange = (count) => {

    setSelectedWordCount(count);

    setQuizSetup(prev => ({

      ...prev,

      selectedWordCount: count

    }));

  };



  return (

    <div className="App">

      <div className="app-header">

        <div className="menu-container">

          <button 

            className={`menu-trigger ${showMenu ? 'active' : ''}`}

            onClick={() => setShowMenu(!showMenu)}

            aria-label="Menu"

          >

            <svg 

              viewBox="0 0 24 24" 

              fill="none" 

              stroke="currentColor" 

              strokeLinecap="round" 

              strokeLinejoin="round"

              strokeWidth="2"

              width="20"

              height="20"

            >

              <path d="M4 12h16" />

              <path d="M4 6h16" />

              <path d="M4 18h16" />

            </svg>

          </button>

          {showMenu && (

            <>

              <div className="menu-overlay" onClick={() => setShowMenu(false)} />

              <div className="menu-dropdown">

                <div className="menu-icons">

                  <button 

                    onClick={() => {

                      navigate('/vocabulary');

                      setShowMenu(false);

                    }}

                    className="menu-icon-button"

                    title="View Progress"

                  >

                    <div className="menu-button-content">

                      <svg viewBox="0 0 24 24" width="24" height="24">

                        <path d="M12 20V10M18 20V4M6 20v-4" stroke="currentColor" strokeWidth="2"/>

                      </svg>

                      <span className="menu-button-label">Vocabulary Progress</span>

                    </div>

                  </button>

                  {/* <button 
                    onClick={() => {
                      navigate('/math');
                      setShowMenu(false);
                    }}
                    className="menu-icon-button"
                    title="Math Practice"
                  >
                    <div className="menu-button-content">
                      <svg viewBox="0 0 24 24" width="24" height="24">
                        <path d="M4 4h16v16H4V4m2 4v2h12V8H6m0 4v2h12v-2H6m0 4v2h8v-2H6" 
                          stroke="currentColor" fill="none" strokeWidth="2"/>
                      </svg>
                      <span className="menu-button-label">Math Practice</span>
                    </div>
                  </button> */}

                  <button 

                    onClick={() => {

                      setShowResetConfirm(true);

                      setShowMenu(false);

                    }}

                    className="menu-icon-button warning"

                    title="Reset Progress"

                  >

                    <div className="menu-button-content">

                      <svg viewBox="0 0 24 24" width="24" height="24">

                        <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2" stroke="currentColor" strokeWidth="2"/>

                      </svg>

                      <span className="menu-button-label">Reset Progress</span>

                    </div>

                  </button>

                 
                </div>

              </div>

            </>

          )}

        </div>

        <h1>Synonym Quiz</h1>

        <div className="header-spacer"></div>

      </div>



      {!quizStarted && !quizCompleted && (

        <div className="start-container">

          <div className="alphabet-selector">

            <label htmlFor="alphabet">Select starting letter(s):</label>

            <div className="alphabet-buttons">

              {alphabetCategories.map(letter => (

                <button

                  key={letter}

                  onClick={() => handleAlphabetSelect(letter)}

                  className={`alphabet-button ${

                    selectedAlphabets.includes(letter) ? 'selected' : ''

                  }`}

                >

                  {letter.toUpperCase()}

                </button>

              ))}

            </div>

            <div className="selected-letters">

              Selected: {

                selectedAlphabets.includes('all') 

                  ? 'All Letters' 

                  : selectedAlphabets.map(l => l.toUpperCase()).join(', ')

              }

            </div>

          </div>

          

          <div className="word-count-selector">

            <label htmlFor="wordCount">

              Number of words: {selectedWordCount}

              <div className="available-words">

                <span className="total-available">

                  {getAvailableWords().totalAvailableWords} words available

                </span>

                <span className="synonym-count">

                  {getAvailableWords().synonymCount} unmastered synonyms

                </span>

              </div>

            </label>

            <div className="slider-container">

              <input

                type="range"

                id="wordCount"

                min="1"

                max={getAvailableWords().totalAvailableWords || 1}

                value={selectedWordCount}

                onChange={(e) => handleWordCountChange(Number(e.target.value))}

                disabled={getFilteredWords().length === 0}

                className="custom-slider"

              />

              <div className="slider-labels">

                <span>1</span>

                <span>{Math.floor(getAvailableWords().totalAvailableWords / 2)}</span>

                <span>{getAvailableWords().totalAvailableWords || 1}</span>

              </div>

            </div>

          </div>

          

          <button 

            onClick={handleStartQuiz}

            className="start-button"

            disabled={getFilteredWords().length === 0}

          >

            Start Quiz

          </button>

        </div>

      )}



      {/* Reset Confirmation Modal */}

      {showResetConfirm && (

        <div className="modal">

          <div className="modal-content">

            <h3>Reset Progress?</h3>

            <p>This will permanently delete all your statistics and progress. This action cannot be undone.</p>

            <div className="modal-buttons">

              <button onClick={() => setShowResetConfirm(false)}>

                Cancel

              </button>

              <button onClick={handleResetStats} className="reset-button">

                Reset All Progress

              </button>

            </div>

          </div>

        </div>

      )}



      {quizStarted && !quizCompleted && (

        <QuizContainer quizData={quizData} onQuizComplete={handleQuizComplete} />

      )}

      {quizCompleted && (

        <>

          <ResultsContainer 

            results={results} 

            onViewStats={() => setShowStats(true)} 

          />

          <div className="result-actions">

            <button onClick={handleRetakeQuiz}>Main Menu</button>

          </div>

        </>

      )}

    </div>

  );

}


