import React from 'react';

function WordDefinitionPopup({ word, data, onClose }) {
  if (!data) return null;

  return (
    <div className="word-definition-popup">
      <div className="popup-content">
        <button className="close-popup" onClick={onClose}>×</button>
        <div className="word-header">
          <h2>{word}</h2>
          {data.phonetic && <p className="phonetic">{data.phonetic}</p>}
        </div>
        <div className="meanings-container">
          {data.meanings?.map((meaning, index) => (
            <div key={index} className="meaning-section">
              <h3 className="part-of-speech">{meaning.partOfSpeech}</h3>
              <ol className="definitions-list">
                {meaning.definitions.map((def, defIndex) => (
                  <li key={defIndex} className="definition-item">
                    <p className="definition">{def.definition}</p>
                    {def.example && (
                      <p className="example">"{def.example}"</p>
                    )}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WordDefinitionPopup; 