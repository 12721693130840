import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/ProgressPage.css';
import { Tooltip } from '../components/Tooltip';

const ProgressPage = ({ userStats }) => {
  const navigate = useNavigate();
  const [progressData, setProgressData] = useState({
    totalQuizzes: userStats?.totalQuizzes || 0,
    averageScore: userStats?.averageScore || 0,
    wordsLearned: userStats?.wordsLearned?.length || 0,
    quizHistory: userStats?.quizHistory || [],
    weakWords: userStats?.weakWords || []
  });

  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const calculateQuizStats = (quiz) => {
    const totalWords = Object.keys(quiz.wordResults || {}).length;
    const correctAnswers = Object.values(quiz.wordResults || {}).reduce(
      (sum, result) => sum + result.correct.length, 0
    );
    const totalAttempts = Object.values(quiz.wordResults || {}).reduce(
      (sum, result) => sum + result.correct.length + result.incorrect.length, 0
    );
    const avgTime = Object.values(quiz.wordResults || {}).reduce(
      (sum, result) => sum + (result.times.reduce((a, b) => a + b, 0) / result.times.length), 0
    ) / totalWords;

    return {
      totalWords,
      correctAnswers,
      accuracy: Math.round((correctAnswers / totalAttempts) * 100),
      avgTime: Math.round(avgTime * 10) / 10
    };
  };

  const handleStartPractice = () => {
    navigate('/practice');
  };

  const getMasteredWords = () => {
    const masteredWords = [];
    userStats.quizHistory.forEach(quiz => {
      Object.entries(quiz.wordResults || {}).forEach(([word, result]) => {
        // A word is mastered if user has correctly identified all its synonyms
        const allSynonymsAttempted = result.allSynonyms.every(synonym => 
          result.correct.includes(synonym)
        );
        if (allSynonymsAttempted && !masteredWords.includes(word)) {
          masteredWords.push(word);
        }
      });
    });
    return masteredWords;
  };

  const getMasteryDate = (word) => {
    for (const quiz of userStats.quizHistory) {
      const result = quiz.wordResults && quiz.wordResults[word];
      if (result && result.allSynonyms.every(synonym => result.correct.includes(synonym))) {
        return quiz.date;
      }
    }
    return null;
  };

  // Sort quiz history by date (most recent first)
  const sortedQuizHistory = [...progressData.quizHistory].sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <div className="progress-page">
      <div className="progress-content">
        <header className="progress-header">
          <div className="header-left">
            <Link to="/" className="back-button">
              <span>←</span> Back
            </Link>
            <h1>Practice</h1>
          </div>
        </header>

        <div className="practice-container">
          <div className="practice-section">
            <h2>Practice Session</h2>
            <button 
              className="start-practice-button"
              onClick={handleStartPractice}
            >
              Start Practice Session
            </button>
          </div>
        </div>

        <div className="mastered-words-container">
          <div className="section-header">
            <div className="header-title">
              <h2>Words Mastered</h2>
              <Tooltip content="Words for which you have correctly identified all synonyms" />
            </div>
            <button 
              onClick={() => navigate('/vocabulary')} 
              className="view-vocabulary-button"
            >
              View All Vocabulary
            </button>
          </div>
          
          {getMasteredWords().length === 0 ? (
            <p className="no-mastered">No words mastered yet. Keep practicing!</p>
          ) : (
            <div className="mastered-words-grid">
              {getMasteredWords().map((word, index) => (
                <div key={index} className="mastered-word-card">
                  <span className="mastered-word">{word}</span>
                  {getMasteredWords().length <= 6 && (
                    <span className="mastery-date">
                      Mastered on: {formatDate(getMasteryDate(word))}
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="history-container">
          <h2>Quiz History</h2>
          {progressData.quizHistory.length === 0 ? (
            <p className="no-history">No quizzes taken yet. Start a quiz to see your history!</p>
          ) : (
            <div className="quiz-history-list">
              {sortedQuizHistory.map((quiz, index) => {
                const stats = calculateQuizStats(quiz);
                return (
                  <div 
                    key={index} 
                    className={`quiz-history-item ${selectedQuiz === index ? 'selected' : ''}`}
                    onClick={() => setSelectedQuiz(selectedQuiz === index ? null : index)}
                  >
                    <div className="quiz-history-header">
                      <div className="quiz-basic-info">
                        <span className="quiz-date">{formatDate(quiz.date)}</span>
                        <span className="quiz-score">Score: {quiz.score || 0}%</span>
                        <span className="quiz-time">
                          Avg Time: {quiz.avgResponseTime ? quiz.avgResponseTime.toFixed(1) : '0.0'}s
                        </span>
                      </div>
                      <div className="quiz-stats">
                        <span className="stat">
                          <span className="stat-label">Words:</span> {quiz.wordsAttempted}
                        </span>
                      </div>
                    </div>
                    
                    {selectedQuiz === index && (
                      <div className="quiz-details">
                        <h3>Detailed Results</h3>
                        
                        {/* Quiz Summary */}
                        <div className="quiz-summary">
                          <div className="summary-stat">
                            <span className="summary-label">Total Words:</span>
                            <span className="summary-value">{stats.totalWords}</span>
                          </div>
                          <div className="summary-stat">
                            <span className="summary-label">Correct Answers:</span>
                            <span className="summary-value">{stats.correctAnswers}</span>
                          </div>
                          <div className="summary-stat">
                            <span className="summary-label">Accuracy:</span>
                            <span className="summary-value">{stats.accuracy}%</span>
                          </div>
                          <div className="summary-stat">
                            <span className="summary-label">Average Time:</span>
                            <span className="summary-value">{stats.avgTime}s</span>
                          </div>
                        </div>

                        {/* Word Results */}
                        <div className="words-list">
                          {Object.entries(quiz.wordResults || {}).map(([word, result]) => (
                            <div key={word} className="word-result">
                              <div className="word-header">
                                <h4>{word}</h4>
                                <div className="word-stats">
                                  <span className="word-stat correct">
                                    ✓ {result.correct.length}
                                  </span>
                                  <span className="word-stat incorrect">
                                    ✗ {result.incorrect.length}
                                  </span>
                                  <span className="word-stat time">
                                    ⏱ {result.times && result.times.length > 0 
                                      ? (result.times.reduce((a, b) => a + b, 0) / result.times.length).toFixed(1) 
                                      : '0.0'}s
                                  </span>
                                </div>
                              </div>

                              <div className="word-content">
                                <div className="definition-section">
                                  <span className="section-label">Definition:</span>
                                  <p className="word-definition">{result.definition}</p>
                                </div>

                                <div className="synonyms-section">
                                  <span className="section-label">All Synonyms:</span>
                                  <div className="synonyms-list">
                                    {result.allSynonyms && result.allSynonyms.map((synonym, i) => (
                                      <span 
                                        key={i} 
                                        className={`synonym-tag ${
                                          result.correct.includes(synonym) 
                                            ? 'correct' 
                                            : result.incorrect.includes(synonym) 
                                              ? 'incorrect' 
                                              : ''
                                        }`}
                                      >
                                        {synonym}
                                        {result.correct.includes(synonym) && <span className="tag-icon">✓</span>}
                                        {result.incorrect.includes(synonym) && <span className="tag-icon">✗</span>}
                                      </span>
                                    ))}
                                  </div>
                                </div>

                                <div className="answers-section">
                                  <div className="answers-grid">
                                    <div className="answer-column">
                                      <span className="answer-label">Correct Answers:</span>
                                      <div className="answers-list">
                                        {result.correct.map((ans, i) => (
                                          <span key={i} className="answer correct">
                                            {ans}
                                            <span className="answer-time">
                                              {result.times[i].toFixed(1)}s
                                            </span>
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                    
                                    {result.incorrect.length > 0 && (
                                      <div className="answer-column">
                                        <span className="answer-label">Incorrect Answers:</span>
                                        <div className="answers-list">
                                          {result.incorrect.map((ans, i) => (
                                            <span key={i} className="answer incorrect">
                                              {ans}
                                              <span className="answer-time">
                                                {result.times[result.correct.length + i].toFixed(1)}s
                                              </span>
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressPage;
