export const mathQuizData = {
  Distance: [
    {
      question: "How many inches are in a foot?",
      options: ["10", "12", "14", "16"],
      answer: "12"
    },
    {
      question: "How many feet are in a yard?",
      options: ["2", "3", "4", "5"],
      answer: "3"
    },
    {
      question: "How many yards are in a mile?",
      options: ["1720", "1740", "1760", "1780"],
      answer: "1760"
    }
  ],
  Weight: [
    {
      question: "How many ounces are in a pound?",
      options: ["12", "14", "16", "18"],
      answer: "16"
    },
    {
      question: "How many pounds are in a ton?",
      options: ["1000", "1500", "2000", "2500"],
      answer: "2000"
    }
  ],
  Counting: [
    {
      question: "How many units are in a dozen?",
      options: ["10", "11", "12", "13"],
      answer: "12"
    },
    {
      question: "How many units are in a gross?",
      options: ["100", "144", "156", "168"],
      answer: "144"
    }
  ],
  Liquid: [
    {
      question: "How many ounces are in a cup?",
      options: ["6", "8", "10", "12"],
      answer: "8"
    },
    {
      question: "How many cups are in a pint?",
      options: ["1", "2", "3", "4"],
      answer: "2"
    },
    {
      question: "How many quarts are in a gallon?",
      options: ["2", "3", "4", "5"],
      answer: "4"
    }
  ],
  Dry: [
    {
      question: "How many pints are in a quart?",
      options: ["1", "2", "3", "4"],
      answer: "2"
    },
    {
      question: "How many quarts are in a peck?",
      options: ["6", "7", "8", "9"],
      answer: "8"
    },
    {
      question: "How many pecks are in a bushel?",
      options: ["2", "3", "4", "5"],
      answer: "4"
    }
  ],
  Time: [
    {
      question: "How many seconds are in a minute?",
      options: ["30", "45", "60", "75"],
      answer: "60"
    },
    {
      question: "How many minutes are in an hour?",
      options: ["45", "50", "55", "60"],
      answer: "60"
    },
    {
      question: "How many hours are in a day?",
      options: ["20", "22", "24", "26"],
      answer: "24"
    }
  ]
}; 