import React, { useState } from 'react';
import { conversionData } from '../data/conversionData';
import '../styles/MathCalculator.css';

function MathCalculator({ category }) {
  const [fromUnit, setFromUnit] = useState('');
  const [toUnit, setToUnit] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [result, setResult] = useState('');

  const conversions = conversionData[category] || [];

  const handleConvert = () => {
    if (!fromUnit || !toUnit || !inputValue) {
      setResult('Please fill in all fields');
      return;
    }

    const conversion = conversions.find(c => c.from === fromUnit && c.to === toUnit);
    if (conversion) {
      const calculatedResult = (parseFloat(inputValue) * conversion.factor).toFixed(2);
      setResult(`${inputValue} ${fromUnit} = ${calculatedResult} ${toUnit}`);
    } else {
      // Check if reverse conversion exists
      const reverseConversion = conversions.find(c => c.from === toUnit && c.to === fromUnit);
      if (reverseConversion) {
        const calculatedResult = (parseFloat(inputValue) / reverseConversion.factor).toFixed(2);
        setResult(`${inputValue} ${fromUnit} = ${calculatedResult} ${toUnit}`);
      } else {
        setResult('Conversion not available');
      }
    }
  };

  const clearCalculator = () => {
    setFromUnit('');
    setToUnit('');
    setInputValue('');
    setResult('');
  };

  return (
    <div className="calculator-card">
      <div className="calculator-header">
        <h2>Conversion Calculator</h2>
        <p className="calculator-description">Convert between different units of {category.toLowerCase()}</p>
      </div>

      <div className="calculator-content">
        {conversions.length > 0 ? (
          <>
            <div className="unit-selectors">
              <div className="select-group">
                <label>From:</label>
                <select 
                  value={fromUnit} 
                  onChange={(e) => setFromUnit(e.target.value)}
                >
                  <option value="">Select unit</option>
                  {[...new Set(conversions.map(c => c.from))].map(unit => (
                    <option key={unit} value={unit}>{unit}</option>
                  ))}
                </select>
              </div>

              <div className="select-group">
                <label>To:</label>
                <select 
                  value={toUnit} 
                  onChange={(e) => setToUnit(e.target.value)}
                >
                  <option value="">Select unit</option>
                  {[...new Set(conversions.map(c => c.to))].map(unit => (
                    <option key={unit} value={unit}>{unit}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="input-group">
              <label>Value:</label>
              <input
                type="number"
                placeholder="Enter value"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>

            <div className="calculator-buttons">
              <button 
                className="convert-button"
                onClick={handleConvert}
                disabled={!fromUnit || !toUnit || !inputValue}
              >
                Convert
              </button>
              <button 
                className="clear-button"
                onClick={clearCalculator}
              >
                Clear
              </button>
            </div>

            {result && (
              <div className="result">
                {result}
              </div>
            )}
          </>
        ) : (
          <p className="no-data">No conversion data available for this category</p>
        )}
      </div>
    </div>
  );
}

export default MathCalculator; 