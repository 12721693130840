import React, { useState, useEffect } from 'react';
import QuizQuestion from './QuizQuestion';
import { useNavigate } from 'react-router-dom';

function QuizContainer({ quizData, onQuizComplete }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timer, setTimer] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [questionTimes, setQuestionTimes] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [showEndQuizConfirm, setShowEndQuizConfirm] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval); // Fixed the syntax error here
  }, []);

  useEffect(() => {
    setQuestionStartTime(Date.now());
  }, [currentQuestionIndex]);

  const handleAnswer = (answer) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = answer;
    setUserAnswers(newUserAnswers);

    const timeTaken = (Date.now() - questionStartTime) / 1000;
    const newQuestionTimes = [...questionTimes];
    newQuestionTimes[currentQuestionIndex] = timeTaken;
    setQuestionTimes(newQuestionTimes);

    // Check if answer is incorrect and device is mobile
    const isMobile = window.innerWidth <= 768;
    const isCorrect = quizData[currentQuestionIndex].allSynonyms.includes(answer);
    
    if (isMobile && !isCorrect) {
      setShowAnswerModal(true);
    }
  };

  const handleNext = () => {
    if (!userAnswers[currentQuestionIndex]) {
      setShowWarning(true);
      return;
    }
    setShowWarning(false);
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowAnswer(false);
    } else {
      finishQuiz();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setShowAnswer(false);
    }
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
  };

  const handleEndQuiz = () => {
    setShowEndQuizConfirm(true);
  };

  const finishQuiz = () => {
    const results = quizData.slice(0, Math.max(currentQuestionIndex + 1, userAnswers.length))
      .map((question, index) => ({
        ...question,
        userAnswer: userAnswers[index] || '',
        timeTaken: questionTimes[index] || 0,
      }));
    onQuizComplete(results);
    navigate('/results');
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowRight' && userAnswers[currentQuestionIndex]) {
        handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrevious();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentQuestionIndex, userAnswers, handleNext, handlePrevious]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const isAnswerCorrect = () => {
    const currentQuestion = quizData[currentQuestionIndex];
    const userAnswer = userAnswers[currentQuestionIndex];
    return currentQuestion.allSynonyms.includes(userAnswer);
  };

  const handleModalClose = () => {
    setShowAnswerModal(false);
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      finishQuiz();
    }
  };

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <div className="timer">Time: {formatTime(timer)}</div>
        <div className="progress">
          Question {currentQuestionIndex + 1} of {quizData.length}
        </div>
        <button onClick={handleEndQuiz} className="end-quiz-button">
          End Quiz
         </button>
      </div>

      {showEndQuizConfirm && (
        <div className="modal">
          <div className="modal-content">
            <h3>End Quiz?</h3>
            <p>Are you sure you want to end the quiz? Only your current progress will be saved.</p>
            <div className="modal-buttons">
              <button onClick={() => setShowEndQuizConfirm(false)}>
                Continue Quiz
              </button>
              <button onClick={finishQuiz} className="end-button">
                End Quiz
              </button>
            </div>
          </div>
        </div>
      )}

      <QuizQuestion
        question={quizData[currentQuestionIndex]}
        onAnswer={handleAnswer}
        userAnswer={userAnswers[currentQuestionIndex]}
        showAnswer={showAnswer}
        setShowAnswer={setShowAnswer}
        showAnswerModal={showAnswerModal}
        onModalClose={handleModalClose}
      />
      
      {showWarning && (
        <div className="warning">Please select an answer before moving to the next question.</div>
      )}
      
      <div className="navigation-buttons">
        <button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
          Previous
        </button>
        {!showAnswerModal && userAnswers[currentQuestionIndex] && 
         !isAnswerCorrect() && !showAnswer && !window.matchMedia('(max-width: 768px)').matches && (
          <button onClick={handleShowAnswer}>Show Answer</button>
        )}
        <button onClick={handleNext}>
          {currentQuestionIndex === quizData.length - 1 ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  );
}

export default QuizContainer;
