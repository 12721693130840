import React, { useState, useEffect } from 'react';

import { Data } from '../data';



function QuizQuestion({ 

  question, 

  onAnswer, 

  userAnswer, 

  showAnswer,

  showAnswerModal,

  onModalClose 

}) {

  const [isPlaying, setIsPlaying] = useState(false);

  const [showAnswerDetails, setShowAnswerDetails] = useState(false);

  const [isAnswerVisible, setIsAnswerVisible] = useState(false);

  const [completeWordData, setCompleteWordData] = useState(null);



  // Reset states when question changes

  useEffect(() => {

    setShowAnswerDetails(false);

    setIsAnswerVisible(false);

    // Get complete word data from Data array

    const fullWordData = Data.find(word => word.word === question.word);

    setCompleteWordData(fullWordData);

  }, [question]);



  const handleChoice = (choice) => {

    if (!userAnswer) {

      onAnswer(choice);

      setShowAnswerDetails(false);

      setIsAnswerVisible(false);

    }

  };



  const isCorrectAnswer = (choice) => {

    return question.allSynonyms.includes(choice);

  };



  const handleShowAnswer = () => {

    setShowAnswerDetails(true);

    setIsAnswerVisible(true);

  };



  const speakWord = (word) => {

    if ('speechSynthesis' in window) {

      window.speechSynthesis.cancel();

      const utterance = new SpeechSynthesisUtterance(word);

      utterance.lang = 'en-US';

      utterance.rate = 0.9;

      

      utterance.onstart = () => setIsPlaying(true);

      utterance.onend = () => setIsPlaying(false);

      utterance.onerror = () => setIsPlaying(false);

      

      window.speechSynthesis.speak(utterance);

    }

  };



  return (

    <div className="quiz-question" role="form" aria-label="Quiz Question">

      <h2 id="question-word">

        {question.word}

        <button 

          onClick={() => speakWord(question.word)}

          className={`audio-button ${isPlaying ? 'playing' : ''}`}

          aria-label={`Listen to pronunciation of ${question.word}`}

          disabled={isPlaying}

        >

          {isPlaying ? (

            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">

              <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/>

            </svg>

          ) : (

            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">

              <path d="M3 9v6h4l5 5V4L7 9H3zm7-.17v6.34L7.83 13H5v-2h2.83L10 8.83z"/>

            </svg>

          )}

        </button>

      </h2>

      

      <div className="choices" role="radiogroup" aria-labelledby="question-word">

        {question.choices.map((choice, index) => (

          <button

            key={index}

            onClick={() => handleChoice(choice)}

            disabled={userAnswer !== undefined}

            className={`choice-button

              ${userAnswer === choice ? 'selected' : ''}

              ${userAnswer === choice && isCorrectAnswer(choice) ? 'correct' : ''}

              ${userAnswer === choice && !isCorrectAnswer(choice) ? 'incorrect' : ''}

            `}

            role="radio"

            aria-checked={userAnswer === choice}

            aria-label={choice}

          >

            {choice}

          </button>

        ))}

      </div>



      {/* Mobile Show Answer Button */}

      {userAnswer && 

       !isCorrectAnswer(userAnswer) && 

       !isAnswerVisible &&

       window.matchMedia('(max-width: 768px)').matches && (

        <button 

          className="show-answer-button"

          onClick={handleShowAnswer}

          aria-label="Show correct answer and definition"

        >

          Show Answer

        </button>

      )}



      {/* Answer Details Section */}

      {(showAnswer || isAnswerVisible) && completeWordData && (

        <div className="answer-details">

          <div className="word-header">

            <div className="word-info">

              <h3>{completeWordData.word}</h3>

              {completeWordData['part-of-speech'] && (

                <span className="part-of-speech">

                  {completeWordData['part-of-speech'].join(', ')}

                </span>

              )}

            </div>

          </div>

          

          {/* Definitions Section */}

          <div className="definition-section">

            <h4>Definitions:</h4>

            <ol className="definitions-list">

              {Array.isArray(completeWordData.definition) ? (

                completeWordData.definition.map((def, index) => (

                  <li key={index} className="definition-item">{def}</li>

                ))

              ) : (

                <li className="definition-item">{completeWordData.definition}</li>

              )}

            </ol>

          </div>

          

          {/* Synonyms Section */}

          <div className="synonyms-section">

            <h4>Synonyms:</h4>

            <div className="synonyms-container">

              <div className="synonyms-grid">

                {completeWordData.synonyms.map((synonym) => (

                  <div key={synonym} className="synonym-wrapper">

                    <span 

                      className={`synonym-tag ${

                        question.allSynonyms.includes(synonym) ? 'active' : 'mastered'

                      }`}

                    >

                      {synonym}

                      {!question.allSynonyms.includes(synonym) && (

                        <span className="mastery-icon">✓</span>

                      )}

                    </span>

                  </div>

                ))}

              </div>

            </div>

          </div>

          

          {/* Example Sentences Section */}

          {completeWordData.sentences && completeWordData.sentences.length > 0 && 

           completeWordData.sentences[0] !== "" && (

            <div className="sentences-section">

              <h4>Example Sentences:</h4>

              <ul className="sentences-list">

                {completeWordData.sentences.map((sentence, index) => (

                  <li key={index} className="sentence-item">

                    <span className="sentence-number">{index + 1}.</span>

                    <p>{sentence}</p>

                  </li>

                ))}

              </ul>

            </div>

          )}



          {/* Mnemonics Section */}

          {completeWordData.mnemonics && completeWordData.mnemonics.length > 0 && 

           completeWordData.mnemonics[0] !== "" && (

            <div className="mnemonics-section">

              <h4>Memory Tips:</h4>

              <ul className="mnemonics-list">

                {completeWordData.mnemonics.map((mnemonic, index) => (

                  mnemonic && <li key={index} className="mnemonic-item">

                    <span className="mnemonic-icon">💡</span>

                    <p>{mnemonic}</p>

                  </li>

                ))}

              </ul>

            </div>

          )}



          {/* Media Section */}

          {((completeWordData.media && completeWordData.media[0] && completeWordData.media[0] !== "") || 

            (completeWordData.image && completeWordData.image[0] && completeWordData.image[0] !== "")) && (

            <div className="media-section">

              <h4>Visual Examples:</h4>

              <div className="media-grid">

                {completeWordData.media.map((url, index) => (

                  url && url !== "" && (

                    <div key={`video-${index}`} className="media-item">

                      <video controls>

                        <source src={url} type="video/mp4" />

                        Your browser does not support the video tag.

                      </video>

                    </div>

                  )

                ))}

                {completeWordData.image.map((url, index) => (

                  url && url !== "" && (

                    <div key={`image-${index}`} className="media-item">

                      <img src={url} alt={`Example of ${completeWordData.word}`} />

                    </div>

                  )

                ))}

              </div>

            </div>

          )}

        </div>

      )}

    </div>

  );

}



export default QuizQuestion;


