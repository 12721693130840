import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import QuizContainer from './QuizContainer';
import { Data } from '../data';
import '../styles/WordKnowledge.css';

function WordKnowledgePage({ quizSetup, setQuizResults, setUserStats }) {
  const navigate = useNavigate();
  const [quizStarted, setQuizStarted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [results, setResults] = useState({});
  const [quizData, setQuizData] = useState([]);
  const [userStatsLocal, setUserStatsLocal] = useState(() => ({
    totalQuizzes: 0,
    averageScore: 0,
    wordsLearned: [],
    quizHistory: [],
    timeStats: { averageResponseTime: 0 }
  }));

  // Add new state for media popup
  const [mediaPopup, setMediaPopup] = useState({
    isOpen: false,
    type: null, // 'image' or 'video'
    content: [],
    currentIndex: 0
  });

  // Handle media icon clicks
  const handleMediaClick = (type, content, e) => {
    e.stopPropagation();
    if (content && content[0] !== "") {
      setMediaPopup({
        isOpen: true,
        type,
        content,
        currentIndex: 0
      });
    }
  };

  // Handle carousel navigation
  const handleCarouselNav = (direction) => {
    setMediaPopup(prev => ({
      ...prev,
      currentIndex: direction === 'next'
        ? (prev.currentIndex + 1) % prev.content.length
        : (prev.currentIndex - 1 + prev.content.length) % prev.content.length
    }));
  };

  // Update local stats when setUserStats is called
  useEffect(() => {
    setUserStats(prevStats => {
      setUserStatsLocal(prevStats);
      return prevStats;
    });
  }, [setUserStats]);

  useEffect(() => {
    if (!quizSetup.filteredWords?.length) {
      navigate('/');
      return;
    }

    const prepareQuizData = () => {
      // Get list of mastered words and synonyms
      const masteredWords = new Set();
      const masteredSynonyms = new Map();

      quizSetup.filteredWords.forEach(word => {
        masteredSynonyms.set(word.word, new Set());
      });

      // Analyze quiz history to find mastered words and synonyms
      userStatsLocal.quizHistory.forEach(quiz => {
        Object.entries(quiz.wordResults || {}).forEach(([word, result]) => {
          if (result.allSynonyms.every(synonym => result.correct.includes(synonym))) {
            masteredWords.add(word);
          } else {
            result.correct.forEach(synonym => {
              if (masteredSynonyms.has(word)) {
                masteredSynonyms.get(word).add(synonym);
              }
            });
          }
        });
      });

      // Filter out mastered words and prepare remaining words
      const availableWords = quizSetup.filteredWords
        .filter(word => !masteredWords.has(word.word))
        .map(word => {
          const remainingSynonyms = word.synonyms.filter(
            synonym => !masteredSynonyms.get(word.word)?.has(synonym)
          );
          return {
            ...word,
            synonyms: remainingSynonyms
          };
        })
        .filter(word => word.synonyms.length > 0);

      if (availableWords.length === 0) {
        navigate('/');
        return [];
      }

      // Create a pool of all synonyms for wrong answers
      const allSynonyms = new Set(
        Data.flatMap(word => word.synonyms)
      );

      const selectedWords = [...availableWords]
        .sort(() => 0.5 - Math.random())
        .slice(0, quizSetup.selectedWordCount);

      const questions = selectedWords.flatMap(word => {
        return word.synonyms.map(synonym => {
          // Get all synonyms that are NOT associated with the current word
          const availableWrongChoices = [...allSynonyms].filter(syn => 
            // Exclude all synonyms of the current word
            !Data.find(w => w.word === word.word)?.synonyms.includes(syn)
          );

          // Get 5 random wrong answers
          const wrongAnswers = [...availableWrongChoices]
            .sort(() => 0.5 - Math.random())
            .slice(0, 5);

          // Combine with correct answer and shuffle
          const choices = [synonym, ...wrongAnswers]
            .sort(() => 0.5 - Math.random());

          return {
            word: word.word,
            correctSynonym: synonym,
            definition: word.definition,
            choices: choices,
            allSynonyms: word.synonyms
          };
        });
      });

      return questions.sort(() => 0.5 - Math.random());
    };

    setQuizData(prepareQuizData());
    setQuizStarted(true);
  }, [quizSetup, navigate, userStatsLocal]);

  const handleQuizComplete = (quizResults) => {
    // Format results by word
    const formattedResults = quizResults.reduce((acc, result) => {
      if (!acc[result.word]) {
        acc[result.word] = {
          word: result.word,
          definition: result.definition,
          allSynonyms: result.allSynonyms,
          correct: [],
          incorrect: [],
          times: []
        };
      }

      if (result.userAnswer === result.correctSynonym) {
        acc[result.word].correct.push(result.userAnswer);
      } else {
        acc[result.word].incorrect.push(result.userAnswer);
      }
      acc[result.word].times.push(result.timeTaken);

      return acc;
    }, {});

    // Calculate overall quiz statistics
    const totalCorrect = Object.values(formattedResults).reduce(
      (sum, { correct }) => sum + correct.length,
      0
    );
    const totalAttempts = Object.values(formattedResults).reduce(
      (sum, { correct, incorrect }) => sum + correct.length + incorrect.length,
      0
    );
    const score = Math.round((totalCorrect / totalAttempts) * 100);
    const avgResponseTime = Object.values(formattedResults).reduce(
      (sum, { times }) => sum + times.reduce((a, b) => a + b, 0) / times.length,
      0
    ) / Object.keys(formattedResults).length;

    // Create quiz history entry
    const quizHistoryEntry = {
      date: new Date().toISOString(),
      score,
      avgResponseTime,
      wordResults: formattedResults,
      wordsAttempted: Object.keys(formattedResults).length
    };

    // Update user statistics
    setUserStats(prev => ({
      ...prev,
      totalQuizzes: prev.totalQuizzes + 1,
      averageScore: Math.round(
        (prev.averageScore * prev.totalQuizzes + score) / (prev.totalQuizzes + 1)
      ),
      wordsLearned: [...new Set([...prev.wordsLearned, ...Object.keys(formattedResults)])],
      quizHistory: [...prev.quizHistory, quizHistoryEntry],
      timeStats: {
        averageResponseTime: Math.round(
          ((prev.timeStats.averageResponseTime * prev.totalQuizzes) + avgResponseTime) /
          (prev.totalQuizzes + 1)
        )
      }
    }));

    setQuizResults(formattedResults);
    setQuizCompleted(true);
    navigate('/results');
  };

  return (
    <div className="word-knowledge-page">
      <div className="header-left">
        <Link to="/" className="back-button">
          <span>←</span> Back
        </Link>
      </div>
      
      {quizStarted && !quizCompleted && (
        <QuizContainer 
          quizData={quizData.map(data => {
            // Find the full word data to get media info
            const wordData = Data.find(w => w.word === data.word);
            return {
              ...data,
              media: wordData?.media || [""],
              image: wordData?.image || [""]
            };
          })}
          onQuizComplete={handleQuizComplete}
          renderWordHeader={(wordData) => (
            <div className="word-title-container">
              <h2>{wordData.word}</h2>
              <div className="media-icons">
                <button 
                  className={`media-icon ${wordData.image[0] ? 'active' : ''}`}
                  onClick={(e) => handleMediaClick('image', wordData.image, e)}
                  disabled={!wordData.image[0]}
                  title={wordData.image[0] ? "View images" : "No images available"}
                >
                  🖼️
                </button>
                <button 
                  className={`media-icon ${wordData.media[0] ? 'active' : ''}`}
                  onClick={(e) => handleMediaClick('video', wordData.media, e)}
                  disabled={!wordData.media[0]}
                  title={wordData.media[0] ? "View videos" : "No videos available"}
                >
                  🎥
                </button>
              </div>
            </div>
          )}
        />
      )}

      {/* Add Media Popup */}
      {mediaPopup.isOpen && (
        <div className="media-popup-overlay" onClick={() => setMediaPopup({ isOpen: false })}>
          <div className="media-popup-content" onClick={e => e.stopPropagation()}>
            <button className="close-popup" onClick={() => setMediaPopup({ isOpen: false })}>
              ✖
            </button>
            
            {mediaPopup.content.length > 1 && (
              <div className="carousel-controls">
                <button 
                  className="carousel-button"
                  onClick={() => handleCarouselNav('prev')}
                >
                  ◀
                </button>
                <span className="carousel-counter">
                  {mediaPopup.currentIndex + 1} / {mediaPopup.content.length}
                </span>
                <button 
                  className="carousel-button"
                  onClick={() => handleCarouselNav('next')}
                >
                  ▶
                </button>
              </div>
            )}

            <div className="media-container">
              {mediaPopup.type === 'image' ? (
                <img 
                  src={mediaPopup.content[mediaPopup.currentIndex]} 
                  alt="Word visualization" 
                />
              ) : (
                <video 
                  controls 
                  autoPlay
                  src={mediaPopup.content[mediaPopup.currentIndex]}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WordKnowledgePage; 