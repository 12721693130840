import React from 'react';

function ResultsContainer({ score, totalQuestions, onMainMenu }) {
  return (
    <div className="results-container">
      <h2>Quiz Results</h2>
      <div className="score-display">
        <p>Your Score: {score} out of {totalQuestions}</p>
        <p>Percentage: {((score / totalQuestions) * 100).toFixed(2)}%</p>
      </div>
      <button 
        className="back-button"
        onClick={onMainMenu}
      >
        Main Menu
      </button>
    </div>
  );
}

export default ResultsContainer;
