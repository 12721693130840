export const conversionData = {
  Distance: [
    { from: 'feet', to: 'inches', factor: 12 },
    { from: 'yards', to: 'feet', factor: 3 },
    { from: 'miles', to: 'feet', factor: 5280 },
  ],
  Weight: [
    { from: 'pounds', to: 'ounces', factor: 16 },
    { from: 'tons', to: 'pounds', factor: 2000 },
  ],
  Counting: [
    { from: 'dozen', to: 'units', factor: 12 },
    { from: 'gross', to: 'dozen', factor: 12 },
  ],
  Liquid: [
    { from: 'cups', to: 'ounces', factor: 8 },
    { from: 'pints', to: 'cups', factor: 2 },
    { from: 'quarts', to: 'pints', factor: 2 },
    { from: 'gallons', to: 'quarts', factor: 4 },
  ],
  Dry: [
    { from: 'quarts', to: 'pints', factor: 2 },
    { from: 'pecks', to: 'quarts', factor: 8 },
    { from: 'bushels', to: 'pecks', factor: 4 },
  ],
  Time: [
    { from: 'minutes', to: 'seconds', factor: 60 },
    { from: 'hours', to: 'minutes', factor: 60 },
    { from: 'days', to: 'hours', factor: 24 },
    { from: 'weeks', to: 'days', factor: 7 },
  ],
}; 