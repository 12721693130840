import React from 'react';
import '../styles/ProgressModal.css';

function ProgressModal({ progress, isOpen, onClose }) {
  if (!isOpen) return null;

  const getCategoryIcon = (category) => {
    const icons = {
      Distance: "📏",
      Weight: "⚖️",
      Counting: "🔢",
      Liquid: "💧",
      Dry: "🌾",
      Time: "⏰"
    };
    return icons[category] || "📊";
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="progress-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>
            <span className="modal-icon">📊</span>
            Progress Overview
          </h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="modal-description">
          Your current mastery level for each measurement category
        </div>

        <div className="progress-grid">
          {Object.entries(progress).map(([category, value]) => (
            <div key={category} className="progress-item">
              <div className="category-header">
                <span className="category-label">
                  <span className="category-icon">{getCategoryIcon(category)}</span>
                  {category}
                </span>
                <span className="progress-value">{value}%</span>
              </div>
              <div className="progress-bar-container">
                <div 
                  className="progress-bar"
                  style={{ 
                    width: `${value}%`,
                    backgroundColor: value >= 80 ? 'var(--secondary-color)' : 'var(--primary-color)'
                  }}
                />
              </div>
              <div className="mastery-level">
                {value >= 80 ? 'Mastered' : 
                 value >= 60 ? 'Proficient' :
                 value >= 40 ? 'Learning' :
                 value >= 20 ? 'Beginner' : 'New'}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProgressModal; 